import { Text } from "@visx/text";
import { scaleLog } from "@visx/scale";
import { Wordcloud } from "@visx/wordcloud";
import { Empty } from 'antd';
import ParentSize from "@visx/responsive/lib/components/ParentSize";

interface WordCloudProps {
    data: any
    colorPallet: any
}


export const WordCloudPlot = ((props: WordCloudProps) => {

    let { data, colorPallet } = props
    let comp: any

    if (data.length == 0 || data == undefined) {
        return comp = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Data!"} />
    }
    else {
        let wordCloudData: any = []

        data.forEach(function (obj) {
            wordCloudData.push(
                {
                    value: obj.count,
                    text: obj.key
                }
            )
        });

        const fontScale = scaleLog({
            domain: [
                Math.min(...wordCloudData.map((w) => w.value)),
                Math.max(...wordCloudData.map((w) => w.value))
            ],
            range: [10, 50]
        });

        const fontSizeSetter = (wordCloudData: any) => fontScale(wordCloudData.value);
        const fixedValueGenerator = () => 0.5;

        comp =
            <ParentSize>
                {({ width, height }) => (
                    <Wordcloud
                        words={wordCloudData}
                        width={width}
                        height={height}
                        fontSize={fontSizeSetter}
                        font={"Tahoma"}
                        padding={5}
                        spiral={"archimedean"}
                        rotate={0}
                        random={fixedValueGenerator}
                    >
                        {(cloudWords) =>
                            cloudWords.map((w, i) => (
                                <Text
                                    key={w.text}
                                    fill={colorPallet[i % colorPallet.length]}
                                    textAnchor={"middle"}
                                    transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                                    fontSize={w.size}
                                    fontFamily={'Tahoma'}
                                >
                                    {w.text}
                                </Text>
                            ))
                        }
                    </Wordcloud>
                )}
            </ParentSize>
    }

    return comp
}
)