import { GeminiApp } from '@gemini-projects/gemini-react-admin-app';
import '@gemini-projects/gemini-react-admin-app/dist/index.css'
import '@aitechnologies-it/va-backoffice-dashboard/dist/index.css'

import axios from 'axios';
import recordActions from './customActions/recordsActions';
import entityActions from './customActions/entityActions'
import customComponent from './components/customComponents'
import customFieldRenders from './components/customFieldRenders'
import customRoutes from './customRoutes/customRoutes'
import entityCallbacks from './entityCallbacks/entityCallBacks'

axios.defaults.baseURL = process.env.REACT_APP_DEFAULT_NAMESPACE_URI


function App() {

  const props = {
    authentication: {
      google: {
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        enabled: true
      }
    }
  }


  return <GeminiApp {...props} recordsActions={recordActions}
    entityActions={entityActions} customComponents={customComponent} 
     clientSideEntityViewConfig={customFieldRenders} customRoutes={customRoutes}
     entityCallbacks={entityCallbacks}
  />
}

export default App;
