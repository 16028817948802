import * as Components from "antd";
import { useRecord } from '@gemini-projects/gemini-react-admin-app';
import { GeminiSchema, recordUtils } from '@gemini-projects/gemini-react-entity-lib'
import { diff as DiffEditor } from "react-ace";
import stringify from 'json-stable-stringify'
import { useState } from "react";
import { EntityManager } from "@gemini-projects/gemini-react-admin-app";

const NamespaceDisplayResolver = (props: { namespace: string }) => {
    const { namespace } = props;

    const [nsRecord] = useRecord({ entity: "NAMESPACE", lk: namespace })
    return nsRecord.data ? <span>{nsRecord.data.displayName}</span> : null
}

const MoveContent = (props: { record: any, schema: GeminiSchema, namespace: string, entity: string, targetNamespace: string, onClose: (a: any) => void }) => {
    const { record, schema, entity, targetNamespace, onClose } = props;
    // return <span>{JSON.stringify(record)}</span>

    const [remoOpe, setRemoOp] = useState('DISABLED');

    const lk = recordUtils.getRecordLk(record, schema);

    const [nsRecord] = useRecord({ entity: "NAMESPACE", lk: targetNamespace })

    const [targetRecord] = useRecord({ entity, lk, namespace: targetNamespace })
    if (targetRecord.checking || targetRecord.state === "LOADING")
        return <Components.Skeleton active={true} />


    // default value for common entities
    let leftValue = record;
    let rigthValue = targetRecord.data ?? {}
    let mode = "json"


    if (schema.name === 'MISSPELLING_DICT'){
        leftValue = (record && JSON.parse(record.jsonDictionary)) ?? {}
        rigthValue = (targetRecord.data && JSON.parse(targetRecord.data.jsonDictionary)) ?? {}
    }

    let leftStringified;
    let rightStringified;

    if (schema.name === 'JSFILEACTION') {
        leftStringified = (record && record.code) ?? {}
        rightStringified = (targetRecord.data && targetRecord.data.code) ?? {}
        mode = "javascript"

    } else {
        leftStringified = stringify(leftValue, { space: 2 });
        rightStringified = stringify(rigthValue, { space: 2 });    
    }


    const updateFN = async () => {
        try {
            setRemoOp("UPDATING")
            await EntityManager.update({
                namespace: targetNamespace,
                entity: entity,
                lk: lk,
                record: record
            })
            setRemoOp("COMPLETE")
        } catch (err) {
            setRemoOp("ERROR")
        }
    }

    const createFN = async () => {
        try {
            setRemoOp("UPDATING")
            await EntityManager.newRecord({
                namespace: targetNamespace,
                entity: entity,
                record: record
            })
            setRemoOp("COMPLETE")
        } catch (err) {
            setRemoOp("ERROR")
        }
    }


    if (remoOpe === 'COMPLETE') {
        return <Components.Result
            status="success"
            title={`Record moved`}
            extra={[
                <Components.Button key="close" onClick={onClose}>Close</Components.Button>,
            ]}
        />
    }

    if (remoOpe === 'ERROR') {
        return <Components.Result
            status="error"
            title={`Some error occurs. Please retry.`}
            extra={[
                <Components.Button key="close" onClick={onClose}>Close</Components.Button>,
            ]}
        />
    }

    if (leftStringified === rightStringified)
        return <Components.Result
            status="success"
            title={`The two records are the same`}
            subTitle="No need to update"
            extra={[
                <Components.Button key="close" onClick={onClose}>Close</Components.Button>,
            ]}
        />

    if (targetRecord.state === "NOT_FOUND") {
        return <Components.Spin spinning={remoOpe === 'UPDATING'}>
            <Components.Result
                status="404"
                title={`Not found`}
                subTitle={`Record not found in target env ${nsRecord.data?.displayName ?? ""}`}
                extra={[
                    <Components.Button key="close" onClick={onClose}>Cancel</Components.Button>,
                    <Components.Button key="create" type="primary" onClick={createFN}>Create Record</Components.Button>
                ]}
            />
        </Components.Spin>
    }

    // @ts-ignore 
    return <div><DiffEditor mode={mode}
        theme="github"
        orientation="beside"
        value={[leftStringified, rightStringified]}
        name="move_code_diff"
        width="100%"
        highlightActiveLine={false}
        editorProps={{ $blockScrolling: true }}
        setOptions={{ animatedScroll: false, dragEnabled: false}}
    />
        <Components.Spin spinning={remoOpe === 'UPDATING'}>
            <Components.Result
                status="warning"
                title="Do you really want to move ?"
                extra={[
                    <Components.Button key="cancel" onClick={onClose}>
                        Cancel
                    </Components.Button>,
                    <Components.Button type="primary" key="move" onClick={updateFN}>
                        Move
                    </Components.Button>
                ]
                }
            />
        </Components.Spin>
    </div>
}



const MoveRecSubMenu = (props: { namespace: string, entity: string, schema: GeminiSchema, record: any }) => {
    const { namespace, entity, schema, record } = props;

    const targettNamespace = moveNamespace(props.namespace)

    const [modalVisible, setModalVisible] = useState<any>({});

    if (!targettNamespace)
        return null;

    return <><Components.Menu.SubMenu title="Move to" key="submenu">
        {targettNamespace.map(ns =>
            <Components.Menu.Item key={`recmenu${props.namespace}${targettNamespace}`} onClick={() => { setModalVisible({ [ns]: true }) }} >
                <NamespaceDisplayResolver namespace={ns} />
            </Components.Menu.Item>
        )}
    </Components.Menu.SubMenu>
        {
            targettNamespace.map(ns =>
                <Components.Modal key={`modal${namespace}${targettNamespace}`} width="80%" title={null} visible={modalVisible[ns]} footer={null} closable={false}>
                    <MoveContent record={record} schema={schema} namespace={namespace} entity={entity} targetNamespace={ns} onClose={() => setModalVisible({ [ns]: false })} />
                </Components.Modal>)
        }
    </>

}

const actionsNS = (props: { namespace: string, entity: string }) => {
    const { entity, namespace } = props;
    
    if(['CHATDAILYSESSION', 'CLUSTERINGANALYSIS'].includes(entity))
        return undefined;
        
    return {
        actions: ({ record, schema }: { record: any, schema: GeminiSchema }) => [
            <MoveRecSubMenu namespace={namespace} entity={entity} schema={schema} record={record}></MoveRecSubMenu>
        ]
    }
}

const moveNamespace = (namespace: string) => {

    switch (namespace) {
        case "APPENGINE_BO":
            return ["INTEGRATION_BO"];
        case "INTEGRATION_BO":
            return ["STAGING_BO"]
        case "STAGING_BO":
            return ["PRODUCTION_BO"]
    }

    return undefined;
}

export default actionsNS;