import { CloseOutlined } from '@ant-design/icons';
import { EntityManager, ObservableArrayRecords, useAllRecord, useRecord } from '@gemini-projects/gemini-react-admin-app';
import { GeminiSchema, recordUtils } from '@gemini-projects/gemini-react-entity-lib';
import * as Components from "antd";
import stringify from 'json-stable-stringify';
import { useState } from "react";
import { diff as DiffEditor } from "react-ace";

const NamespaceDisplayResolver = (props: { targetNamespace: string }) => {
    const { targetNamespace } = props;
    const [targetNsRecord] = useRecord({ entity: "NAMESPACE", lk: targetNamespace })
    return targetNsRecord.data ? <span>{targetNsRecord.data.displayName}</span> : null
}

const lkSorter = (schema: GeminiSchema) => {

    return (a: any, b: any) => {
        const alk = recordUtils.getRecordLk(a, schema);
        const blk = recordUtils.getRecordLk(b, schema);
        return alk.localeCompare(blk)
    }
}

const ActionButton = (props: { targetNamespace: string, entity: string, row: { lk: string, sourceRecord?: any, targetRecord?: any, actionType: 'UPDATED' | 'NEW' | 'DELETED' } }) => {

    const { row, targetNamespace, entity } = props
    const [loading, setLoading] = useState(false)


    const updateFN = async () => {
        setLoading(true)
        try {
            await EntityManager.update({
                namespace: targetNamespace,
                entity: entity,
                lk: row.lk,
                record: row.sourceRecord
            })
        } catch (err) {
        }
        setLoading(false)
    }

    const createFN = async () => {
        setLoading(true)
        try {
            await EntityManager.newRecord({
                namespace: targetNamespace,
                entity: entity,
                record: row.sourceRecord
            })
        } catch (err) {
        }
        setLoading(false)
    }

    const deleteFn = async () => {
        setLoading(true)
        try {
            await EntityManager.delete({
                namespace: targetNamespace,
                entity: entity,
                lk: row.lk
            })
        } catch (err) {
        }
        setLoading(false)
    }

    switch (row.actionType) {
        case 'NEW':
            return <Components.Popconfirm placement="bottom" title={'Are you sure'} onConfirm={createFN}><Components.Button size="small" loading={loading} type="link">Add</Components.Button></Components.Popconfirm>
        case 'DELETED':
            return <Components.Popconfirm placement="bottom" title={'Are you sure'} onConfirm={deleteFn}><Components.Button size="small" loading={loading} type="link">Delete</Components.Button></Components.Popconfirm>
        case 'UPDATED':
            return <Components.Popconfirm placement="bottom" title={'Are you sure'} onConfirm={updateFN}><Components.Button size="small" loading={loading} type="link">Update</Components.Button></Components.Popconfirm>
    }
}

export const diffEntity = (sourceRecords: ObservableArrayRecords, targetRecords: ObservableArrayRecords, schema: GeminiSchema) => {
    const diffArray: { lk: string, sourceRecord?: any, targetRecord?: any, actionType: 'UPDATED' | 'NEW' | 'DELETED' }[] = []

    if (sourceRecords.data && targetRecords.data) {
        sourceRecords.data.sort(lkSorter(schema))
        targetRecords.data.sort(lkSorter(schema))

        let i = 0, j = 0;
        while (i < sourceRecords.data.length || j < targetRecords.data.length) {
            let sourceRecord = sourceRecords.data[i]
            let targetRecord = targetRecords.data[j]

            if (!sourceRecord) {
                diffArray.push({ lk: recordUtils.getRecordLk(targetRecord, schema), targetRecord, actionType: 'DELETED' })
                j++
                continue;
            }

            if (!targetRecord) {
                diffArray.push({ lk: recordUtils.getRecordLk(sourceRecord, schema), sourceRecord, actionType: 'NEW' })
                i++
                continue;
            }

            const alk = recordUtils.getRecordLk(sourceRecord, schema);
            const blk = recordUtils.getRecordLk(targetRecord, schema);
            if (alk !== blk) {
                const cmp = alk.localeCompare(blk);
                if (cmp < 0) {
                    diffArray.push({ lk: alk, sourceRecord, actionType: 'NEW' })
                    i++
                } else {
                    diffArray.push({ lk: blk, targetRecord, actionType: 'DELETED' })
                    j++
                }
            } else {

                // check here

                // default value for common entities
                let leftValue = sourceRecord;
                let rigthValue = targetRecord ?? {}
                /*  REMOVED FOR PERFORMACE REASON
                    if (schema.name === 'MISSPELLING_DICT') {
                    leftValue = (sourceRecord && JSON.parse(sourceRecord.jsonDictionary)) ?? {}
                    rigthValue = (targetRecord && JSON.parse(targetRecord.jsonDictionary)) ?? {}
                } */

                let leftStringified;
                let rightStringified;

                if (schema.name === 'JSFILEACTION') {
                    leftStringified = (sourceRecord && sourceRecord.code) ?? ""
                    rightStringified = (targetRecord && targetRecord.code) ?? ""

                } else if (schema.name === 'MISSPELLING_DICT') {
                    leftStringified = (sourceRecord && sourceRecord.jsonDictionary) ?? ""
                    rightStringified = (targetRecord && targetRecord.jsonDictionary) ?? ""
                } else {
                    leftStringified = stringify(leftValue, { space: 2 });
                    rightStringified = stringify(rigthValue, { space: 2 });
                }

                if (leftStringified !== rightStringified) {
                    diffArray.push({ lk: alk, sourceRecord, targetRecord, actionType: 'UPDATED' })
                }
                i++
                j++
            }
        }
    }

    return diffArray;
}


export const DiffEntityBody = (props: { diffArray: any, targetNamespace: string, entity: string }) => {
    const { targetNamespace, entity, diffArray } = props
    return <Components.Table size="small"
        columns={[
            { title: 'Record Key', dataIndex: 'lk', key: 'lk' },
            {
                title: 'Status', dataIndex: 'actionType', key: 'actionType',
                render: (tag: any) => {
                    const color: any = { 'NEW': 'blue', 'UPDATED': 'orange', 'DELETED': 'red' }

                    return (
                        <Components.Tag color={color[tag]} key={tag}>
                            {tag}
                        </Components.Tag>
                    );
                }
            },
            {
                title: 'Action', key: 'action', render: (v, record) => <ActionButton row={record} targetNamespace={targetNamespace} entity={entity} />
            }
        ]}
        rowKey="lk"
        expandable={{
            expandedRowRender: row => {

                // default value for common entities
                let leftValue = row.sourceRecord;
                let rigthValue = row.targetRecord ?? {}
                let mode = "json"


                if (entity === 'MISSPELLING_DICT') {
                    leftValue = (row.sourceRecord && JSON.parse(row.sourceRecord.jsonDictionary)) ?? {}
                    rigthValue = (row.targetRecord && JSON.parse(row.targetRecord.jsonDictionary)) ?? {}
                }

                let leftStringified;
                let rightStringified;

                if (entity === 'JSFILEACTION') {
                    leftStringified = (row.sourceRecord && row.sourceRecord.code) ?? {}
                    rightStringified = (row.targetRecord && row.targetRecord.code) ?? {}
                    mode = "javascript"

                } else {
                    leftStringified = stringify(leftValue, { space: 2 });
                    rightStringified = stringify(rigthValue, { space: 2 });
                }

                return <DiffEditor key={row.lk} mode={mode}
                    theme="github"
                    orientation="beside"
                    value={[leftStringified, rightStringified]}
                    name={row.lk}
                    width="100%"
                />
            },
            rowExpandable: record => record.actionType === 'UPDATED'
        }}
        dataSource={diffArray}
    />
}

const MoveContent = (props: { schema: GeminiSchema, namespace: string, entity: string, targetNamespace: string, onClose: (a: any) => void }) => {
    const { schema, entity, namespace, targetNamespace, onClose } = props;

    const [targetNsRecord] = useRecord({ entity: "NAMESPACE", lk: targetNamespace })
    const [sourceNsRecord] = useRecord({ entity: "NAMESPACE", lk: namespace })


    const [sourceRecords] = useAllRecord({ entity, namespace })
    const [targetRecords] = useAllRecord({ entity, namespace: targetNamespace })


    const diffArray = diffEntity(sourceRecords, targetRecords, schema);

    /* if (targetRecords.checking || targetRecords.state === "LOADING" || sourceRecords.checking || sourceRecords.state === "LOADING")
        return <Components.Skeleton active={true} /> */

    const title = <Components.PageHeader
        title={`Diff from ${sourceNsRecord.data?.displayName} to ${targetNsRecord.data?.displayName}`}
        extra={[<Components.Button icon={<CloseOutlined></CloseOutlined>} onClick={onClose}>Close</Components.Button>
        ]}
    />

    return <>
        {title}
        {diffArray.length > 0 ? <DiffEntityBody {...props} diffArray={diffArray} /> : <Components.Result
            status="success"
            title={`No Diff`}
            subTitle="No records to update"
        />}
    </>;
}

const MoveEnvSubMenu = (props: { namespace: string, entity: string, schema: GeminiSchema }) => {
    const { namespace, entity, schema } = props;
    const targettNamespace = moveNamespace(namespace)

    const [modalVisible, setModalVisible] = useState<any>({});

    if (!targettNamespace)
        return null;

    return <><Components.Menu.SubMenu title="Diff" key={`submenu_${namespace}${entity}`}>
        {targettNamespace.map(ns =>
            <Components.Menu.Item key={`entmenu${namespace}${entity}${ns}`} onClick={() => {
                setModalVisible({ [ns]: true })
            }} >
                <NamespaceDisplayResolver targetNamespace={ns} />
            </Components.Menu.Item>
        )}
    </Components.Menu.SubMenu>
        {targettNamespace.map(ns =>
            <Components.Modal width="80%" title={null} visible={modalVisible[ns]} footer={null} closable={false}>
                <MoveContent schema={schema} namespace={props.namespace} entity={props.entity} targetNamespace={ns} onClose={() => setModalVisible({ [ns]: false })} />
            </Components.Modal>)
        }
    </>

}

const actionsNS = (props: { namespace: string, entity: string }) => {

    const { entity, namespace } = props;

    if (['CHATDAILYSESSION', 'CLUSTERINGANALYSIS', 'CLUSTERINGCOMPARISON'].includes(entity))
        return undefined;

    return {
        actions: ({ schema }: { record: any, schema: GeminiSchema }) => [
            <MoveEnvSubMenu namespace={namespace} entity={entity} schema={schema}></MoveEnvSubMenu>
        ]
    }
}

const moveNamespace = (namespace: string) => {

    switch (namespace) {
        case "APPENGINE_BO":
            return ["INTEGRATION_BO"];
        case "INTEGRATION_BO":
            return ["STAGING_BO"]
        case "STAGING_BO":
            return ["PRODUCTION_BO"]
    }

    return undefined;
}

export default actionsNS;