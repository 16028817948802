import { EntityManager, NamespaceContext } from "@gemini-projects/gemini-react-admin-app";
import { EntityViewConfig } from "@gemini-projects/gemini-react-entity-lib/dist/components/common/types";
import { List } from 'antd'
import { useContext, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { MessageOutlined, LockOutlined } from '@ant-design/icons';

const SupportHistory = ({ innerState, namespace }) => {
    const [supportChats, setSupportChats] = useState<any>(undefined);


    useEffect(() => {
        const sessionId = innerState?.initRecord?.id

        if (sessionId) {
            EntityManager.getRecord({ entity: "CHATSUPPORTSESSION", lk: sessionId, namespace: namespace })
                .then(res => {
                    const sc = res.data;
                    setSupportChats(sc)
                })
        }

    }, [])

    if (!supportChats)
        return null;

    return <List
        style={{ maxWidth: "800px", marginTop: "20px" }}
        size="small"
        header={<div><b>Switched To Support - </b> {supportChats.isClosed ? <b>CLOSED <LockOutlined /></b> : <b>STILL OPEN <MessageOutlined /> </b>}</div>}
        bordered
        dataSource={supportChats.messages}
        renderItem={(item: any) =>
            <List.Item style={{ backgroundColor: item.isHelpdesk ? "#8fdba01c" : undefined }}>
                <div style={{ whiteSpace: "pre-line" }}>
                    <p><b>{item.isHelpdesk ? "HelpDesk" : "User"} - {dayjs(item.date).format("YYYY-MM-DD HH:mm:ss")}</b></p>
                    <p>{item.message}</p>
                </div>
            </List.Item>}

    />

}

export const clientEntityViewConfig = (props: { namespace: string, entity: string }): EntityViewConfig | undefined => {
    const { namespace, entity } = props;
    if (entity === "CHATDAILYSESSION") {
        return {
            /* tableView: {
                enabledFields: ["id", "created", "topic", "isClosed", "isSwitched", "isAuthenticated", "feedback", "clientId"]
            },*/
            recordView: {
                fields: {
                    'history': {
                        viewValueRender: ({ value }) => <div>
                            <List
                                style={{ maxWidth: "800px" }}
                                size="small"
                                header={<div><b>Chat History</b></div>}
                                bordered
                                dataSource={value}
                                renderItem={(item: any) => <List.Item style={{ backgroundColor: item.isBot ? "#8fdba01c" : undefined }}><div style={{ whiteSpace: "pre-line" }}><b>{item.isBot ? "Bot" : "User"}:</b> {item.message}</div></List.Item>}
                            />
                        </div>
                    },
                    'supportHistory': {
                        viewValueRender: (props) => <SupportHistory {...props} namespace={namespace} />
                    }
                }

            }
        }
    }
    return undefined
}

export default clientEntityViewConfig;