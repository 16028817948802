import { useAllRecord, useRecord } from "@gemini-projects/gemini-react-admin-app";
import { Button, Dropdown, List, Popover, Select, Skeleton, Space } from "antd";
import ELK from 'elkjs/lib/elk.bundled.js'
import { useEffect, useState } from "react";
import ReactFlow, { Background, Controls, Edge, FlowElement, MiniMap, Node, ReactFlowProvider, useStore, useZoomPanHelper } from "react-flow-renderer";
import ActionEdge from "./flow/ActionEdge";
import ActionNode from "./flow/ActionNode";
import AuthGuardNode from "./flow/AuthGuardNode";
import GroupNode from "./flow/GroupNode";
import NodeToIntent from "./flow/NodeToIntent";
import StartNode from "./flow/StartNode";
import { WarningOutlined } from '@ant-design/icons';
import JsActionNode from "./flow/JsActionNode";
import { hashCode } from "./flow/utils";
import ChannelOverrideNode from "./flow/ChannelOverrideNode";
const mapNodeToFlowElements = (
    node: any,
    parentId?: string,
    offset?: { x?: number; y?: number }
): FlowElement[] => {
    const position = {
        x: (node.x ?? 0) + (offset?.x ?? 0),
        y: (node.y ?? 0) + (offset?.y ?? 0),
    };

    const element: Node = {
        data: { ...node.data, width: node.width, height: node.height },
        id: node.id,
        position,
        style: node.style,
        type: node.type,
        ...node._temp,
    };
    // if (parentId) element.data.parentId = parentId;

    /* if (node._temp?.type === 'group') {
        // @ts-ignore
        element.data.width = node.width;
        // @ts-ignore
        element.data.children = node.children?.map((child) => child.id);
    } */

    const edges =
        node.edges?.reduce((_edges: any, edge: any) => {
            const _edge = (edge as unknown) as any;
            //if (_edge._temp?.visible !== false) {
            let sourceId = _edge.sources[0];

            if (_edge._temp?.sourcePortId) {
                sourceId += `__${_edge._temp?.sourcePortId}`;
            }

            _edges.push({
                id: _edge.id,
                source: sourceId,
                target: _edge.targets[0],
                arrowHeadType: "arrowclosed",
                // type: "step",
                ..._edge
            });
            //   }

            return _edges;
        }, [] as Edge[]) ?? [];

    const children =
        node.children?.flatMap((childNode: any) =>
            mapNodeToFlowElements(childNode, node.id, { x: node.x, y: node.y ?? 0 })
        ) ?? [];

    if (element.id === 'root') return [...edges, ...children];
    return [element, ...edges, ...children];
};

const useBotConfiguration = ({ namespace }: { namespace: string }): [boolean, { intents: any, patternMatches: any, actions: any, basicSettings: any, jsactions: any, useCases: any }] => {
    const [actionData, actionDataReload] = useAllRecord({ namespace, entity: "ACTION" })
    const [intentData, intentDataReload] = useAllRecord({ namespace, entity: "INTENT" })
    const [patternMatchData, pmReload] = useAllRecord({ namespace, entity: "PATTERN_MATCH" })
    const [jsactions, jsCodeReload] = useAllRecord({ namespace, entity: "JSFILEACTION" })
    const [smUsecases, smUCReload] = useAllRecord({ namespace, entity: "SMARTFLOW_USECASE" })
    const [basicSettings] = useRecord({ namespace, entity: "APIF_BASIC_SETTINGS", lk: "default" })


    const ready = (actionData.data && !actionData.checking &&
        intentData.data && !intentData.checking &&
        patternMatchData.data && !patternMatchData.checking &&
        basicSettings.data && !basicSettings.checking &&
        jsactions.data && !jsactions.checking &&
        smUsecases.data && !smUsecases.checking
    ) ?? false;

    const res = { intents: intentData.data, patternMatches: patternMatchData.data, actions: actionData.data, basicSettings: basicSettings.data, jsactions: jsactions.data, useCases: smUsecases.data }

    return [ready, res]
}


const nodeConfig = { width: 400, height: 300 };
const startNodeConfig = { width: 400, height: 250 };
const intentStyle = {
    "borderColor": "blue"
}

const edgeStyle = {
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelStyle: { fill: '#096dd9' },
    labelBgStyle: { fill: '#e6f7ff', strokeWidth: 1, stroke: '#91d5ff' }
}

const disambiguationEdgeStyle = {
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelStyle: { fill: '#d46b08' },
    labelBgStyle: { fill: '#fff7e6', strokeWidth: 1, stroke: '#ffd591' }
}

const noAuthEdgeStyle = {
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelStyle: { fill: '#cf1322' },
    labelBgStyle: { fill: '#fff1f0', strokeWidth: 1, stroke: '#ffa39e' }
}

const authhEdgeStyle = {
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelStyle: { fill: '#389e0d' },
    labelBgStyle: { fill: '#f6ffed', strokeWidth: 1, stroke: '#b7eb8f' }
}

const telegramEdgeStyle = {
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelStyle: { fill: '#2013cf' },
    labelBgStyle: { fill: '#f0f0ff', strokeWidth: 1, stroke: '#2013cf' }
}


const groupIdSt = (id: string) => "G_" + id
const intentId = (intent: { id: string }) => "I_" + intent.id
const actionId = (action: { id: string }) => "A_" + action.id
const jsUseCaseSt = (action: string, uc: any) => "AJS_" + action + "_UC_" + uc

const intentIdSt = (id: string) => "I_" + id
const pmIdSt = (id: string) => "PM_" + id
const actionIdSt = (id: string) => "A_" + id
const startIdSt = (id: string) => "S_" + id

const actionToActionEdge = (source: string, target: string) => "E_" + actionIdSt(source) + "_" + actionIdSt(target)
const startToActionEdge = (source: string, target: string) => "SE_" + startIdSt(source) + "_" + actionIdSt(target)
const actionToJSUsecase = (action: string, label: any) => "E_" + actionIdSt(action) + "_" + actionIdSt(label)


const actionToIntentEdge = (source: string, target: string) => "E_" + actionIdSt(source) + "_" + intentIdSt(target)

const actionsDep = (startId: any, mainActions: any, intentsIDs: any, actionsMap: any, jsActionsMap: any, useCases: Array<any>, actions: Array<any>) => {
    let doneActions: Array<any> = [];
    const children: Array<any> = [];
    const edges: Array<any> = []
    const errors: Array<any> = []
    for (var action of actions) {
        const dep = actionDep(startId, mainActions, intentsIDs, actionsMap, jsActionsMap, useCases, doneActions, action);
        children.push(...dep.children)
        edges.push(...dep.edges)
        errors.push(...dep.errors)
    }
    return { children, edges, errors }
}
const actionDep = (startId: any, mainActions: any, intentsIDs: any, actionsMap: any, jsActionsMap: any, useCases: Array<any>, doneActions: Array<string>, action: any): { children: Array<any>, edges: Array<any>, errors: Array<any> } => {
    const children: any = [];
    const edges: any = [];
    const errors: any = [];

    const prefixSt = startId;

    if (doneActions.includes(startId + action.id)) {
        return { children: [], edges: [], errors: [] }
    }

    doneActions.push(startId + action.id);

    // means that we are in a different intent flow 
    if (mainActions[action.id] && mainActions[action.id] != startId) {
        children.push({ id: prefixSt + actionId(action), data: { from: "actionButton", label: action.id, action, targetIntent: mainActions[action.id] }, type: "nodeToIntent", ...nodeConfig })

    } else {

        // the action LABEL of the first action here should be equals to the NODE ACTION (because the edje is created outstide)
        // if channel / auth override we create other node groups 

        let defaultChannelActionLabel = prefixSt + actionId(action);
        if (action.channelActionOverride && action.channelActionOverride.telegramEnabled) {
            children.push({ id: defaultChannelActionLabel, data: { label: action.id, action }, type: "chanellOverrideNode", ...nodeConfig })
            const targetAction = actionsMap[action.channelActionOverride.telegramAction]

            edges.push({
                id: prefixSt + actionToActionEdge(action.id, action.channelActionOverride.telegramAction) + "TELEGRAM", label: "TELEGRAM", ...telegramEdgeStyle,
                sources: [defaultChannelActionLabel], targets: [prefixSt + "_TG" + actionIdSt(action.channelActionOverride.telegramAction)],
            })

            const innerAuthActionDep = actionDep(prefixSt + "_TG", mainActions, intentsIDs, actionsMap, jsActionsMap, useCases, doneActions, targetAction)
            children.push(...innerAuthActionDep.children)
            edges.push(...innerAuthActionDep.edges)
            errors.push(...innerAuthActionDep.errors)

            defaultChannelActionLabel = prefixSt + actionId(action) + "_DEFAULT_CH"
            edges.push({
                id: prefixSt + actionToActionEdge(action.id, "DEFAULT_CH"), label: "DEFAULT CHANNEL", ...authhEdgeStyle,
                sources: [prefixSt + actionIdSt(action.id)], targets: [defaultChannelActionLabel],
            })

        }

        let authActionLabel = defaultChannelActionLabel;
        if (action.authGuard?.enabled) {

            // children.push({ id: prefixSt + actionId(action), data: { label: action.id, action }, type: "authGuardNode", ...nodeConfig })

            if (action.authGuard.action) {
                children.push({ id: authActionLabel, data: { label: action.id, action }, type: "authGuardNode", ...nodeConfig })
                const targetAction = actionsMap[action.authGuard.action]

                edges.push({
                    id: prefixSt + actionToActionEdge(action.id, action.authGuard.action) + "AUTH", label: "✖️ NOT AUTHENTICATED", ...noAuthEdgeStyle,
                    sources: [authActionLabel], targets: [prefixSt + actionIdSt(action.authGuard.action)],
                })

                const innerAuthActionDep = actionDep(startId, mainActions, intentsIDs, actionsMap, jsActionsMap, useCases, doneActions, targetAction)
                children.push(...innerAuthActionDep.children)
                edges.push(...innerAuthActionDep.edges)
                errors.push(...innerAuthActionDep.errors)

                authActionLabel = prefixSt + actionId(action) + "_AUTH"
                edges.push({
                    id: prefixSt + actionToActionEdge(action.id, "AUTH"), label: "✔️ AUTHENTICATED", ...authhEdgeStyle,
                    sources: [prefixSt + actionIdSt(action.id)], targets: [authActionLabel],
                })
            }

        }

        let actionNode = { id: authActionLabel, data: { label: action.id, action }, type: "actionNode", ...nodeConfig }
        if (action.actionType === "JS_CODE") {
            const { from, file } = action.jsCode;
            if (from && from === "FILE" && jsActionsMap[file]) {
                actionNode.data["actionJs"] = jsActionsMap[file]
                actionNode.data["useCases"] = useCases
            }
        }

        if (action.actionType === "JS_CODE") {
            const { from, file } = action.jsCode;
            if (from && from === "FILE") {
                const jsAction = jsActionsMap[file]
                const { code } = jsAction

                if (!action.isJsExecuted && Array.isArray(useCases) && useCases.length > 0) {
                    // execute the JSCODE to find the return statements and draw the actions edges

                    for (var j = 0; j < useCases.length; j++) {
                        const smartUC = useCases[j]
                        if (smartUC.actions?.map((s: string) => s.toLowerCase()).includes(action.id.toLowerCase())) {

                            const finalUSECASES = smartUC.useCases
                            for (var i = 0; i < finalUSECASES.length; i++) {
                                const uc = finalUSECASES[i]
                                const { data, label } = uc
                                let paramData = data;

                                let executedRes: any = undefined;
                                if (data) {
                                    try {
                                        paramData = JSON.parse(data)
                                        paramData.actionName = action.id
                                    } catch (e) {
                                    }
                                    try {
                                        const executableCode = eval(code + "(a) => action(a)");
                                        executedRes = executableCode(paramData)
                                        if (typeof executedRes === "string") {
                                            executedRes = {
                                                actionType: "MESSAGE",
                                                message: {
                                                    value: executedRes
                                                }
                                            }
                                        }
                                        if (executedRes && executedRes.actionType && executedRes.actionType === "ACTION") {
                                            const finalAction = executedRes.action
                                            const targetAction = actionsMap[finalAction]

                                            if (targetAction) {
                                                executedRes = targetAction
                                            } else {
                                                errors.push({ type: "ACTION_NOT_FOUND", sourceAction: action.id, targetAction: finalAction })
                                            }

                                        }
                                       

                                    } catch (e) {
                                        console.error(`Following error is for: ${action.id} - ${label}`)
                                        console.error(e)
                                        errors.push({ type: "JS_CODE_ERROR", sourceAction: action.id, useCase: label })
                                    }
                                }

                                const uchash = hashCode(action.id + JSON.stringify(uc))
                                const jsUseCaseId = jsUseCaseSt(action.id, uchash);
                                children.push({ id: uchash + jsUseCaseId, data: { usecase: uc }, type: "jsActionUsecase", ...nodeConfig })
                                edges.push({
                                    id: uchash + actionToJSUsecase(action.id, i), ...edgeStyle,
                                    sources: [authActionLabel], targets: [uchash + jsUseCaseId],
                                })

                                if (executedRes) {
                                    const targetActionId = jsUseCaseId + "_ACTION";
                                    const label = executedRes.id ?? action.id // when executedRes is an action we need that label
                                    let targetAction = { id: targetActionId, label, ...executedRes, isJsExecuted: true, jsExecutedContext: { uc, action } }

                                    edges.push({
                                        id: uchash + jsUseCaseId + "_E", ...edgeStyle,
                                        sources: [uchash + jsUseCaseId], targets: [uchash + actionId(targetAction)],
                                    })

                                    const innerActionDep = actionDep(uchash, mainActions, intentsIDs, actionsMap, jsActionsMap, useCases, doneActions, targetAction)
                                    children.push(...innerActionDep.children)
                                    edges.push(...innerActionDep.edges)
                                    errors.push(...innerActionDep.errors)
                                }

                            }
                        }
                    }
                }

                if (action.isJsExecuted) {
                    const jsExecutedContext = action.jsExecutedContext;
                    //actionNode


                    const { data, label } = jsExecutedContext.uc
                    let paramData = data;

                    let executedRes: any = undefined;
                    if (data) {
                        try {
                            paramData = JSON.parse(data)
                            paramData.actionName = jsExecutedContext.actionName ?? action.id
                            paramData.context = jsExecutedContext.context
                        } catch (e) {
                        }
                        try {
                            const executableCode = eval(code + "(a) => action(a)");
                            executedRes = executableCode(paramData)
                            if (typeof executedRes === "string") {
                                executedRes = {
                                    actionType: "MESSAGE",
                                    message: {
                                        value: executedRes
                                    }
                                }
                            }


                        } catch (e) {
                            console.error(`Following error is for: ${action.id} - ${label}`)
                            console.error(e)
                            errors.push({ type: "JS_CODE_ERROR", sourceAction: action.id, useCase: label })
                        }
                    }

                    if (executedRes) {
                        const { authGuard, ...refinedAction } = action;
                        action = { ...refinedAction, ...executedRes };
                        actionNode.data['action'] = action
                    }
                }
            }
        }


        children.push(actionNode)

        if (action.actionType === "ACTION_LIST") {
            if (!action.actionList.actions) {
                errors.push({ type: "EMPTY_ACTION_LIST", sourceAction: action.id })
            } else {
                for (const al of action.actionList.actions) {

                    if (al.webViewUrl) {
                        // not need a node for now
                        const targetAction = { }
                        break;
                    }

                    if (al.action && !al.context) {

                        if (actionsMap[al.action]) {

                            const targetAction = { ...actionsMap[al.action], isJsExecuted: action.isJsExecuted, jsExecutedContext: action.jsExecutedContext }
                            edges.push({
                                id: prefixSt + actionToActionEdge(action.id, al.action), label: al.label, ...edgeStyle,
                                sources: [authActionLabel], targets: [prefixSt + actionIdSt(al.action)],
                            })

                            const innerActionDep = actionDep(startId, mainActions, intentsIDs, actionsMap, jsActionsMap, useCases, doneActions, targetAction)
                            children.push(...innerActionDep.children)
                            edges.push(...innerActionDep.edges)
                            errors.push(...innerActionDep.errors)

                        } else {
                            errors.push({ type: "ACTION_NOT_FOUND", sourceAction: action.id, targetAction: al.action })
                        }
                    }
                    if (al.intent) {
                        if (intentsIDs.includes(al.intent)) {
                            edges.push({
                                id: prefixSt + actionToIntentEdge(action.id, al.intent), label: al.label, ...edgeStyle,
                                sources: [authActionLabel], targets: [prefixSt + intentIdSt(al.intent)],
                            })

                            children.push({ id: prefixSt + intentIdSt(al.intent), data: { from: "intentButton", label: al.intent, targetIntent: startIdSt(intentIdSt(al.intent)) }, type: "nodeToIntent", ...nodeConfig })
                        } else {
                            errors.push({ type: "INTENT_NOT_FOUND", sourceAction: action.id, targetIntent: al.intent })
                        }
                    }


                    if (action.isJsExecuted && !al.action && al.context) {
                        const jsExecutedContext = { uc: action.jsExecutedContext.uc, context: al.context };
                        const idHash = hashCode(JSON.stringify(jsExecutedContext)).toString()
                        const useCaseHash = hashCode(action.id + JSON.stringify(action.jsExecutedContext.uc)).toString()

                        const targetJSAction = { ...action.jsExecutedContext.action, id: action.id + idHash, label: action.id, isJsExecuted: true, jsExecutedContext: { uc: action.jsExecutedContext.uc, context: al.context, actionName: action.id } }

                        edges.push({
                            id: prefixSt + actionToActionEdge(action.id, idHash), label: al.label, ...edgeStyle,
                            sources: [authActionLabel], targets: [useCaseHash + actionIdSt(action.id + idHash)],
                        })

                        const innerActionDep = actionDep(useCaseHash, mainActions, intentsIDs, actionsMap, jsActionsMap, useCases, doneActions, targetJSAction)
                        children.push(...innerActionDep.children)
                        edges.push(...innerActionDep.edges)
                        errors.push(...innerActionDep.errors)
                    }

                    // TODO WebView

                    if (action.isJsExecuted && al.action && al.context) {
                        const jsExecutedContext = { uc: action.jsExecutedContext.uc, lable: al.label, action: al.action, context: al.context };
                        const idHash = hashCode(JSON.stringify(jsExecutedContext)).toString()

                        const jsInnerHash = prefixSt + hashCode(action.id + JSON.stringify(al)).toString()
                        edges.push({
                            id: prefixSt + actionToActionEdge(action.id, idHash), label: al.label, ...edgeStyle,
                            sources: [authActionLabel], targets: [jsInnerHash + actionIdSt(action.id + idHash)],
                        })

                        const targetJSAction = { ...actionsMap[al.action], id: action.id + idHash, label: al.label, isJsExecuted: true, jsExecutedContext: { uc: action.jsExecutedContext.uc, context: al.context, actionName: al.action } }

                        const innerActionDep = actionDep(jsInnerHash, mainActions, intentsIDs, actionsMap, jsActionsMap, useCases, doneActions, targetJSAction)
                        children.push(...innerActionDep.children)
                        edges.push(...innerActionDep.edges)
                        errors.push(...innerActionDep.errors)

                    }
                }
            }
        }

        if (action.actionType === "QUESTION_FLOW") {
            const finalAction = action.questionFlow?.finalAction
            const targetAction = actionsMap[finalAction]

            if (targetAction) {
                edges.push({
                    id: prefixSt + actionToActionEdge(action.id, finalAction), ...edgeStyle,
                    sources: [authActionLabel], targets: [prefixSt + actionIdSt(finalAction)],
                })

                const innerActionDep = actionDep(startId, mainActions, intentsIDs, actionsMap, jsActionsMap, useCases, doneActions, targetAction)
                children.push(...innerActionDep.children)
                edges.push(...innerActionDep.edges)
                errors.push(...innerActionDep.errors)

            } else {
                errors.push({ type: "ACTION_NOT_FOUND", sourceAction: action.id, targetAction: finalAction })
            }
        }



    }

    return { children, edges, errors };
}


const nodeTypes = {
    groupNode: GroupNode,
    actionNode: ActionNode,
    startNode: StartNode,
    nodeToIntent: NodeToIntent,
    authGuardNode: AuthGuardNode,
    chanellOverrideNode: ChannelOverrideNode,
    jsActionUsecase: JsActionNode
};

const edgeTypes = {
    actionEdge: ActionEdge
}

const FullFlowViewInner = ({ namespace }: { namespace: string }) => {

    const [graph, setFlows] = useState<any>(null)
    const store = useStore();

    const { setCenter } = useZoomPanHelper();

    const [errorPopoverVisible, setPopoverVisible] = useState(false)

    // contains Actual DATA and records

    const [configReady, config] = useBotConfiguration({ namespace });


    const onSelect = (v, o?) => {
        const { nodes, height, transform } = store.getState();
        const [, , zoom] = transform;

        if (nodes.length) {
            const found = nodes.find((n) => n.type === "startNode" && n.id === v);

            if (found) {
                const x = found.__rf.position.x + found.__rf.width / 2;
                const y = found.__rf.position.y + height / 2 - 10;
                setCenter(x, y, zoom);
            }
        }
    }

    useEffect(() => {
        if (configReady) {

            const intents = config.intents;
            const actions = config.actions;
            const jsactions = config.jsactions
            const patternMatches = config.patternMatches;
            const basicSettings = config.basicSettings
            const useCases = config.useCases

            const intentsChildren: Array<any> = intents.map((intent: any) => {
                return {
                    id: groupIdSt(intentIdSt(intent.id)), draggable: false, data: { intent, type: "intent" }, type: "groupNode", intent,
                    layoutOptions: {
                        algorithm: 'layered',
                        // 'elk.alignment': 'LEFT',
                        //'elk.layered.layering.strategy': "LONGEST_PATH",
                        'elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',

                        'elk.direction': 'DOWN'
                    }
                }
            })

            const actionMap = actions.reduce((acc: any, curr: any) => (acc[curr.id] = curr, acc), {})
            const patterMatchMap = patternMatches.reduce((acc: any, curr: any) => (acc[curr.id] = curr, acc), {})
            const jsActionsMap = jsactions.reduce((acc: any, curr: any) => (acc[curr.id] = curr, acc), {})

            const intentsAction = intents.reduce((acc: any, intent: any) => {
                const startingAction = intent.action ?? intent.id;
                acc[startingAction] = startIdSt(intentIdSt(intent.id))
                return acc;
            }, {})

            const intentsIDs = intents.reduce((acc: any, intent: any) => {
                return [...acc, intent.id]
            }, [])

            const uniquePatternMatchActions = patternMatches.reduce((acc: any, pm: any) => {
                const startingAction = pm.action;
                if (!intentsAction[startingAction])
                    acc[startingAction] = startIdSt(pmIdSt(pm.id))
                return acc;
            }, {})

            const intentsWithPatternMarchesByAction = patternMatches.reduce((acc: any, pm: any) => {
                const startingAction = pm.action;
                if (intentsAction[startingAction]) {
                    if (Array.isArray(acc[startingAction]))
                        acc[startingAction].push(pm)
                    else
                        acc[startingAction] = [pm]
                }
                return acc;
            }, {})



            // collect the starting actions, in order to point to a single flow if the target action appears in other flows
            const mainActions = { ...intentsAction, ...uniquePatternMatchActions }
            if (basicSettings.defaultAuthGuard.enabled) {
                mainActions[basicSettings.defaultAuthGuard.action] = startIdSt("DEFAULT_AUTH_GUARD")
            }

            // WELCOME GROUP
            const welcomeNode = {
                id: groupIdSt("WELCOME"), draggable: false, data: { welcome: basicSettings, type: "welcome" }, type: "groupNode",
                layoutOptions: {
                    algorithm: 'layered',
                    // 'elk.alignment': 'LEFT',
                    //'elk.layered.layering.strategy': "LONGEST_PATH",
                    'elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',

                    'elk.direction': 'DOWN'
                }
            }
            const welComestartingNode = { id: startIdSt("WELCOME"), draggable: false, data: { welcome: basicSettings, type: "welcome" }, type: "startNode", ...startNodeConfig }
            welcomeNode['children'] = [welComestartingNode]
            welcomeNode['edges'] = []
            welcomeNode['edges'].push({ id: startToActionEdge("WELCOME", basicSettings.welcomeAction.name), sources: [startIdSt("WELCOME")], targets: [startIdSt("WELCOME") + actionIdSt(basicSettings.welcomeAction.name)] })
            const welcomeNodeDep = actionsDep(startIdSt("WELCOME"), mainActions, intentsIDs, actionMap, jsActionsMap, useCases, [actionMap[basicSettings.welcomeAction.name]]);
            welcomeNode['children'] = welcomeNode['children'].concat(welcomeNodeDep.children)
            welcomeNode['edges'] = welcomeNode['edges'].concat(welcomeNodeDep.edges)


            // all the actions starting from INTENTS   
            const fullIntentsGraph = intentsChildren.map(ic => {
                const { intent } = ic
                const startingNode = { id: startIdSt(intentIdSt(intent.id)), draggable: false, data: { intent, type: "intent" }, type: "startNode", ...startNodeConfig }

                const children = [startingNode]
                const edges: any = []
                let errors: any = []

                const prefixIDSt = startIdSt(intentIdSt(intent.id))

                const startingActions: any = [];
                let needDefaultAction = true;
                if (intent.pattern_match?.actions) {
                    for (const ia of intent.pattern_match?.actions) {
                        const action = actionMap[ia.action]
                        startingActions.push(action)
                        edges.push({
                            id: startToActionEdge(intentIdSt(intent.id), action.id),
                            sources: [startIdSt(intentIdSt(intent.id))], targets: [prefixIDSt + actionIdSt(action.id)]
                        })
                        if (!ia.match)
                            needDefaultAction = false;
                    }
                }
                if (needDefaultAction) {
                    const target = intent.action ?? intent.id;
                    const action = actionMap[target]

                    if (action) {

                        startingActions.push(action)
                        edges.push({
                            id: startToActionEdge(intentIdSt(intent.id), action.id),
                            sources: [startIdSt(intentIdSt(intent.id))], targets: [prefixIDSt + actionIdSt(action.id)]
                        })

                        if (intentsWithPatternMarchesByAction[target]) {
                            startingNode.data['patternMatches'] = intentsWithPatternMarchesByAction[target]
                        }
                    } else {
                        errors.push({ type: "ACTION_NOT_FOUND", sourceAction: intent.id, targetAction: target })
                    }
                }

                if (intent.disambiguation_action && intent.disambiguation_action != intent.action) {
                    const target = intent.disambiguation_action;
                    const action = actionMap[target]

                    if (action) {
                        startingActions.push(action)
                        edges.push({
                            id: startToActionEdge(intentIdSt(intent.id), action.id), label: "DISAMBIGUATION", ...disambiguationEdgeStyle,

                            sources: [startIdSt(intentIdSt(intent.id))], targets: [prefixIDSt + actionIdSt(action.id)]
                        })
                    } else {
                        errors.push({ type: "ACTION_NOT_FOUND", sourceAction: intent.id, targetAction: target })
                    }

                }

                // children and edges merged
                const dep = actionsDep(prefixIDSt, mainActions, intentsIDs, actionMap, jsActionsMap, useCases, startingActions);
                let res = { ...ic };
                res['children'] = children.concat(dep.children)
                res['edges'] = edges.concat(dep.edges)
                res['errors'] = errors.concat(dep.errors)
                //console.log(res)
                return res;
            })


            // all the ACTIONS starting from pattern match that are not already handled by intents
            const fullPMGraph = patternMatches.filter(pm => !intentsAction[pm.action]).map(pm => {

                const gruupNode = {
                    id: groupIdSt(pmIdSt(pm.id)), draggable: false, data: { patternMatch: pm, type: "patternMatch" }, type: "groupNode",
                    layoutOptions: {
                        algorithm: 'layered',
                        // 'elk.alignment': 'LEFT',
                        //'elk.layered.layering.strategy': "LONGEST_PATH",
                        'elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',

                        'elk.direction': 'DOWN'
                    }
                }

                const startingNode = { id: startIdSt(pmIdSt(pm.id)), draggable: false, data: { patternMatch: pm, type: "patternMatch" }, type: "startNode", ...startNodeConfig }
                const children = [startingNode]
                const edges: any = []
                let errors: any = []

                const startingActions: any = [];
                const action = actionMap[pm.action]

                let res = { ...gruupNode, children, errors };
                if (action) {
                    startingActions.push(action)

                    const prefixIDSt = startIdSt(pmIdSt(pm.id))
                    edges.push({ id: startToActionEdge(pmIdSt(pm.id), pm.action), sources: [startIdSt(pmIdSt(pm.id))], targets: [prefixIDSt + actionIdSt(pm.action)] })

                    const dep = actionsDep(prefixIDSt, mainActions, intentsIDs, actionMap, jsActionsMap, useCases, startingActions);
                    res['children'] = children.concat(dep.children)
                    res['edges'] = edges.concat(dep.edges)
                    res['errors'] = errors.concat(dep.errors)
                } else {
                    errors.push({ type: "ACTION_NOT_FOUND", sourceAction: pm.id, targetAction: pm.action })
                }
                return res;
            })

            // disambiguation ACTIONS 


            const elk = new ELK()

            let flChildrens: any = [welcomeNode];

            if (basicSettings.defaultAuthGuard.enabled) {

                // AUTH GUARD GROUP
                const authGuardNode = {
                    id: groupIdSt("DEFAULT_AUTH_GUARD"), draggable: false, data: { defaultAuthGuard: basicSettings.defaultAuthGuard, type: "defaultAuthGuard" }, type: "groupNode",
                    layoutOptions: {
                        algorithm: 'layered',
                        // 'elk.alignment': 'LEFT',
                        //'elk.layered.layering.strategy': "LONGEST_PATH",
                        'elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',

                        'elk.direction': 'DOWN'
                    }
                }
                const authGuardSartingNode = { id: startIdSt("DEFAULT_AUTH_GUARD"), draggable: false, data: { defaultAuthGuard: basicSettings.defaultAuthGuard, type: "defaultAuthGuard" }, type: "startNode", ...startNodeConfig }
                authGuardNode['children'] = [authGuardSartingNode]
                authGuardNode['edges'] = []
                authGuardNode['edges'].push({ id: startToActionEdge("DEFAULT_AUTH_GUARD", basicSettings.defaultAuthGuard.action), sources: [startIdSt("DEFAULT_AUTH_GUARD")], targets: [startIdSt("DEFAULT_AUTH_GUARD") + actionIdSt(basicSettings.defaultAuthGuard.action)] })
                const authGuardNodeDep = actionsDep(startIdSt("DEFAULT_AUTH_GUARD"), mainActions, intentsIDs, actionMap, jsActionsMap, useCases, [actionMap[basicSettings.defaultAuthGuard.action]]);

                authGuardNode['children'] = authGuardNode['children'].concat(authGuardNodeDep.children)
                authGuardNode['edges'] = authGuardNode['edges'].concat(authGuardNodeDep.edges)

                flChildrens = flChildrens.concat([authGuardNode])
            }


            flChildrens = flChildrens.concat(fullIntentsGraph).concat(fullPMGraph);
            const graph = {
                id: "root",
                // layoutOptions: { 'elk.algorithm': 'mrtree' },
                children: flChildrens,
                edges: []
            }


            elk.layout(graph, {
                layoutOptions: {
                    algorithm: 'layered',
                    'elk.direction': 'DOWN',
                    'elk.spacing.nodeNode': '30',
                    //'elk.mrtree.searchOrder': "BFS"
                    //'elk.spacing.portPort': '500'
                    //'elk.hierarchyHandling': 'INCLUDE_CHILDREN'
                    //'elk.separateConnectedComponents': false
                    //                   'nodePlacement.bk.fixedAlignment': 'BALANCED',
                    //                 considerModelOrder: 'NODES_AND_EDGES',
                    //               edgeRouting: 'SPLINES',
                    // nodeSpacing: '40',

                    // @ts-ignore
                    //'spacing.baseValue': 30,
                },
            })
                .then((g) => {
                    const converted = mapNodeToFlowElements(g)
                    setFlows({ flows: flChildrens, elements: converted })
                })
                .catch(console.error)

        }

    }, [configReady])


    if (!config)
        return <div style={{ margin: 24 }}>ERROR</div>

    if (!configReady || (!graph || !graph.elements)) {
        return <div style={{ margin: 24 }}><Skeleton active /></div>
    }


    const flows = graph.flows.map(fl => {

        if (fl.data.intent) {
            const { intent } = fl.data;
            return { label: "I - " + intent.id, value: startIdSt(intentIdSt(intent.id)) }
        }
        if (fl.data.patternMatch) {
            const { patternMatch } = fl.data;
            return { label: "PM - " + patternMatch.id, value: startIdSt(pmIdSt(patternMatch.id)) }
        }
        if (fl.data.welcome) {
            const { welcome } = fl.data;
            return { label: "WELCOME", value: startIdSt("WELCOME") }
        }
        if (fl.data.defaultAuthGuard) {
            const { defaultAuthGuard } = fl.data;
            return { label: "DEFAULT AUTH GUARD", value: startIdSt("DEFAULT_AUTH_GUARD") }
        }
        return null
    }).filter(a => a != null)

    const dangerFlows = graph.flows.map(fl => {
        if (fl.errors && fl.errors.length > 0) {
            let errors = fl.errors.map(e => {
                if (e.type === "EMPTY_ACTION_LIST") {
                    return { ...e, label: "EMPTY ACTION LIST: " + e.sourceAction }
                }
                if (e.type === "ACTION_NOT_FOUND") {
                    return { ...e, label: "ACTION NOT FOUND:  " + e.targetAction + "\nSource: " + e.sourceAction }
                }
                if (e.type === "INTENT_NOT_FOUND") {
                    return { ...e, label: "INTENT NOT FOUND:  " + e.targetIntent + "\nSource: " + e.sourceAction }
                }
                if (e.type === "JS_CODE_ERROR") {
                    return { ...e, label: "JS USECASE ERROR:  " + e.useCase + "\nSource: " + e.sourceAction }
                }
                return e;
            })

            if (fl.data.intent) {
                const { intent } = fl.data;
                return { label: "I - " + intent.id, value: startIdSt(intentIdSt(intent.id)), errors }
            }
            if (fl.data.patternMatch) {
                const { patternMatch } = fl.data;
                return { label: "PM - " + patternMatch.id, value: startIdSt(pmIdSt(patternMatch.id)), errors }
            }
            if (fl.data.welcome) {
                const { welcome } = fl.data;
                return { label: "WELCOME", value: startIdSt("WELCOME"), errors }
            }
            if (fl.data.defaultAuthGuard) {
                const { defaultAuthGuard } = fl.data;
                return { label: "DEFAULT AUTH GUARD", value: startIdSt("DEFAULT_AUTH_GUARD"), errors }
            }

        }
        return null;
    }).filter(a => a != null)

    return <div style={{ height: "100%", width: "100%" }}>

        <ReactFlow elements={graph.elements} nodeTypes={nodeTypes} edgeTypes={edgeTypes}
            style={{ background: "#f8f9fa" }}>

            <div style={{ position: "absolute", left: 10, top: 10, zIndex: 5 }}>
                <Space>
                    <div style={{ backgroundColor: "white", padding: 10, boxShadow: "0 3px 5px 3px rgb(0 0 0 / 10%)" }}>
                        <Space>
                            Select starting flow:
                            <Select
                                style={{ width: 300 }}
                                showSearch
                                placeholder="no flow selected"
                                options={flows}
                                onSelect={onSelect}
                            >
                            </Select>
                        </Space>
                    </div>

                    {dangerFlows.length > 0 &&
                        <Popover placement="bottomLeft" overlayStyle={{
                            width: "500px"
                        }} content={
                            <List
                                className="demo-loadmore-list"
                                itemLayout="horizontal"
                                dataSource={dangerFlows}
                                renderItem={(flow: any) => (
                                    <List.Item >
                                        <List.Item.Meta
                                            title={<Button type="link" onClick={() => { setPopoverVisible(false); onSelect(flow.value); }} danger>
                                                {flow.label}
                                            </Button>}
                                            description={<List
                                                size="small"
                                                dataSource={flow.errors}
                                                renderItem={(e: any) => <List.Item style={{ whiteSpace: "pre-line" }}>{e.label}</List.Item>}
                                            />}
                                        />
                                    </List.Item>
                                )} />

                        } trigger="click"
                            visible={errorPopoverVisible}
                            onVisibleChange={setPopoverVisible}
                        >
                            <Button danger icon={<WarningOutlined />}>Flow Errors</Button>
                        </Popover>
                    }

                </Space>
            </div>


            <Controls />
            <Background color="black" gap={16} />
            <MiniMap style={{ backgroundColor: "#383840" }} />
        </ReactFlow>

    </div>
}



export const FullFlowView = ({ namespace }: { namespace?: { type: string, value: any } }) => {
    // let fix = "PRODUCTION_BO";
    if (!namespace) {
        return "Need A Namespace (TODO ERROR)"
    }

    return <ReactFlowProvider>
        <FullFlowViewInner namespace={namespace.value} />
    </ReactFlowProvider>
}

export default FullFlowView