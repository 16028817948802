
import { useState, useEffect } from 'react'
import { useRecord, AuthService } from '@gemini-projects/gemini-react-admin-app';
import { WarningOutlined } from '@ant-design/icons';

import * as Components from "antd";
import { Dashboard } from 'components/metricComponents/Dashboard'

import lineData from 'components/metricComponents/assets/lineData.json'
import barData from 'components/metricComponents/assets/barData.json'
import pieData from 'components/metricComponents/assets/pieData.json'
import wordCloudData from 'components/metricComponents/assets/wordCloudData.json'
import moment from 'moment';

import Axios from 'axios';


const getFixedInterval = (to, from) => {
    const diff = to.diff(from, 'seconds')
    if (diff < 120) { return { type: 'fixed', value: '1s', label: 'minutes' } }
    else if (diff > 120 && diff <= 300) { return { type: 'fixed', value: '5s', label: 'minutes' } }
    else if (diff > 300 && diff <= 1800) { return { type: 'fixed', value: '30s', label: 'minutes' } }
    else if (diff > 1800 && diff <= 7200) { return { type: 'fixed', value: '1m', label: 'minutes' } }
    else if (diff > 7200 && diff <= 43200) { return { type: 'fixed', value: '5m', label: 'minutes' } }
    else if (diff > 43200 && diff <= 86400) { return { type: 'fixed', value: '30m', label: 'minutes' } }
    else if (diff > 86400 && diff <= 259200) { return { type: 'fixed', value: '1h', label: 'minutes' } }
    else if (diff > 259200 && diff <= 604800) { return { type: 'fixed', value: '3h', label: 'minutes' } }
    else if (diff > 604800 && diff <= 2.506e+6) { return { type: 'fixed', value: '12h', label: 'minutes' } }
    else if (diff > 2.506e+6 && diff <= 5.256e+6) { return { type: 'calendar', value: '1d', label: 'minutes' } }
    else if (diff > 5.256e+6 && diff <= 2.891e+7) { return { type: 'calendar', value: '1w', label: 'minutes' } }
    else if (diff > 2.891e+7) { return { type: 'calendar', value: '1M', label: 'minutes' } }
}

const isUndefined = (v) => v != undefined;

export const DashboardRoot = (props) => {

    const { dashboardID, dashboardNamespace } = props
    const dashboardRealID = dashboardID.value
    const dashboardNamespaceReadlID = dashboardNamespace?.value
    let from = moment().startOf('day')
    let to = moment()
    const [state, setState] = useState<any>(null)
    const [dateRange, setDateRange] = useState({ from: from.toDate().toISOString(), to: to.toDate().toISOString() })
    const [interval, setInterval] = useState(getFixedInterval(to, from))
    const [datePickerDisabled, setDatePickerDisabled] = useState<boolean>(true)

    useEffect(() => {
        if (dashboardRealID == 'hardcoded') {
            let metaData = {
                id: 0,
                title: "",
                metrics: [
                    {
                        title: "Average Feedback",
                        layout: {
                            lg: { x: 0, y: 0, w: 2, h: 1 },
                            md: { x: 0, y: 0, w: 3, h: 1 },
                            sm: { x: 0, y: 0, w: 2, h: 1 },
                            xs: { x: 0, y: 0, w: 2, h: 1 },
                            xxs: { x: 0, y: 1, w: 2, h: 1 }
                        },
                        component: { name: "GAUGE" },
                        config: {}
                    },
                    {

                        title: "Switched / Started Chat",
                        layout: {
                            lg: { x: 2, y: 0, w: 2, h: 1 },
                            md: { x: 3, y: 0, w: 3, h: 1 },
                            sm: { x: 2, y: 0, w: 2, h: 1 },
                            xs: { x: 2, y: 0, w: 2, h: 1 },
                            xxs: { x: 0, y: 2, w: 2, h: 1 }
                        },
                        component: { name: "ANTD_PROGRESS_CIRCLE" },
                        config: {
                            showInfo: true
                        }
                    },
                    {

                        title: "All Closed / Started Chat",
                        layout: {
                            lg: { x: 4, y: 0, w: 2, h: 1 },
                            md: { x: 6, y: 0, w: 3, h: 1 },
                            sm: { x: 4, y: 0, w: 2, h: 1 },
                            xs: { x: 0, y: 1, w: 2, h: 1 },
                            xxs: { x: 0, y: 3, w: 2, h: 1 }
                        },
                        component: { name: "ANTD_PROGRESS_CIRCLE" },
                        config: {

                        }
                    },
                    {

                        title: "ALL - Init/Message/Close/Error",
                        layout: {
                            lg: { x: 0, y: 1, w: 2, h: 1 },
                            md: { x: 0, y: 1, w: 3, h: 1 },
                            sm: { x: 0, y: 1, w: 2, h: 1 },
                            xs: { x: 2, y: 1, w: 2, h: 1 },
                            xxs: { x: 0, y: 4, w: 2, h: 1 }
                        },
                        component: { name: "ANTD_STATISTIC" },
                        config: {
                            prefix: "->",
                            precision: 2
                        }
                    },
                    {

                        title: "ALL - Init/Message/Close/Error",
                        layout: {
                            lg: { x: 2, y: 1, w: 2, h: 1 },
                            md: { x: 3, y: 1, w: 3, h: 1 },
                            sm: { x: 2, y: 1, w: 2, h: 1 },
                            xs: { x: 0, y: 2, w: 2, h: 1 },
                            xxs: { x: 0, y: 5, w: 2, h: 1 }
                        },
                        component: { name: "ANTD_STATISTIC" },
                        config: {
                            prefix: "!+"

                        }
                    },
                    {

                        title: "ALL - Init/Message/Close/Error",
                        layout: {
                            lg: { x: 4, y: 1, w: 2, h: 1 },
                            md: { x: 6, y: 1, w: 3, h: 1 },
                            sm: { x: 4, y: 1, w: 2, h: 1 },
                            xs: { x: 2, y: 2, w: 2, h: 1 },
                            xxs: { x: 0, y: 6, w: 2, h: 1 }
                        },
                        component: { name: "ANTD_STATISTIC" },
                        config: {
                        }
                    },
                    {

                        title: "ALL - Init/Message/Close/Error",
                        layout: {
                            lg: { x: 0, y: 2, w: 2, h: 1 },
                            md: { x: 0, y: 2, w: 3, h: 1 },
                            sm: { x: 0, y: 2, w: 2, h: 1 },
                            xs: { x: 0, y: 3, w: 2, h: 1 },
                            xxs: { x: 0, y: 7, w: 2, h: 1 }
                        },
                        component: { name: "ANTD_STATISTIC" },
                        config: {
                            suffix: "// 100"
                        }
                    },
                    // {

                    //     title: "ALL - Init/Message/Close/Error",
                    //     layout: {
                    //         lg: { x: 2, y: 2, w: 2, h: 1 },
                    //         md: { x: 3, y: 2, w: 3, h: 1 },
                    //         sm: { x: 2, y: 2, w: 2, h: 1 },
                    //         xs: { x: 2, y: 3, w: 2, h: 1 },
                    //         xxs: { x: 0, y: 8, w: 2, h: 1 }
                    //     },
                    //     component: "ANTD_STATISTIC",
                    //     config: {
                    //         valueStyle: { color: '#cf1322' }
                    //     }
                    // },
                    // {

                    //     title: "ALL - Init/Message/Close/Error",
                    //     layout: {
                    //         lg: { x: 4, y: 2, w: 2, h: 1 },
                    //         md: { x: 6, y: 2, w: 3, h: 1 },
                    //         sm: { x: 4, y: 2, w: 2, h: 1 },
                    //         xs: { x: 0, y: 4, w: 2, h: 1 },
                    //         xxs: { x: 0, y: 9, w: 2, h: 1 }
                    //     },
                    //     component: "ANTD_STATISTIC",
                    //     config: {
                    //         precision: 5
                    //     }
                    // },
                    // {

                    //     title: "Top 15 Intents",
                    //     layout: {
                    //         lg: { x: 6, y: 0, w: 6, h: 3 },
                    //         md: { x: 0, y: 3, w: 9, h: 2 },
                    //         sm: { x: 0, y: 3, w: 6, h: 2 },
                    //         xs: { x: 0, y: 5, w: 4, h: 2 },
                    //         xxs: { x: 0, y: 10, w: 2, h: 2 }
                    //     },
                    //     component: "VISX_WORDCLOUD",
                    //     config: {}
                    // },
                    // {

                    //     title: "Switched to Contact Center Over Time",
                    //     layout: {
                    //         lg: { x: 0, y: 3, w: 12, h: 2 },
                    //         md: { x: 0, y: 5, w: 9, h: 2 },
                    //         sm: { x: 0, y: 5, w: 6, h: 2 },
                    //         xs: { x: 0, y: 7, w: 4, h: 2 },
                    //         xxs: { x: 0, y: 11, w: 2, h: 2 }
                    //     },
                    //     component: "NIVO_BAR",
                    //     config: {
                    //         color_scheme: function ChangeColor(e) {
                    //             if (e.id == "BadSwitch") {
                    //                 return "orange"
                    //             } else if (e.id == "GoodSwitch") {
                    //                 return "red"
                    //             } else {
                    //                 return "blue"
                    //             }
                    //         }
                    //     }
                    // },
                    // {
                    //     title: "Good & Bad Switches",
                    //     layout: {
                    //         lg: { x: 0, y: 5, w: 3, h: 2 },
                    //         md: { x: 0, y: 7, w: 4.5, h: 2 },
                    //         sm: { x: 0, y: 7, w: 3, h: 2 },
                    //         xs: { x: 0, y: 9, w: 4, h: 2 },
                    //         xxs: { x: 0, y: 12, w: 2, h: 2 }
                    //     },
                    //     component: "NIVO_PIE",
                    //     config: {}
                    // },
                    // {

                    //     title: "Good & Bad Switches",
                    //     layout: {
                    //         lg: { x: 3, y: 5, w: 3, h: 2 },
                    //         md: { x: 4.5, y: 7, w: 4.5, h: 2 },
                    //         sm: { x: 3, y: 7, w: 3, h: 2 },
                    //         xs: { x: 0, y: 11, w: 4, h: 2 },
                    //         xxs: { x: 0, y: 13, w: 2, h: 2 }
                    //     },
                    //     component: "NIVO_PIE",
                    //     config: {}
                    // },
                    // {

                    //     title: "Good & Bad Switches",
                    //     layout: {
                    //         lg: { x: 6, y: 5, w: 3, h: 2 },
                    //         md: { x: 0, y: 9, w: 4.5, h: 2 },
                    //         sm: { x: 0, y: 9, w: 3, h: 2 },
                    //         xs: { x: 0, y: 13, w: 4, h: 2 },
                    //         xxs: { x: 0, y: 14, w: 2, h: 2 }
                    //     },
                    //     component: "NIVO_PIE",
                    //     config: {}
                    // },
                    // {

                    //     title: "Good & Bad Switches",
                    //     layout: {
                    //         lg: { x: 9, y: 5, w: 3, h: 2 },
                    //         md: { x: 4.5, y: 9, w: 4.5, h: 2 },
                    //         sm: { x: 3, y: 9, w: 3, h: 2 },
                    //         xs: { x: 0, y: 15, w: 4, h: 2 },
                    //         xxs: { x: 0, y: 15, w: 2, h: 2 }
                    //     },
                    //     component: "NIVO_PIE",
                    //     config: {}
                    // },
                    // {

                    //     title: "Chats Over Time",
                    //     layout: {
                    //         lg: { x: 0, y: 7, w: 12, h: 2 },
                    //         md: { x: 0, y: 11, w: 9, h: 2 },
                    //         sm: { x: 0, y: 11, w: 6, h: 2 },
                    //         xs: { x: 0, y: 17, w: 4, h: 2 },
                    //         xxs: { x: 0, y: 16, w: 2, h: 2 }
                    //     },
                    //     component: "NIVO_LINE",
                    //     config: {}
                    // },
                    // {

                    //     title: "Chats Over Another Time",
                    //     layout: {
                    //         lg: { x: 0, y: 9, w: 12, h: 2 },
                    //         md: { x: 0, y: 13, w: 9, h: 2 },
                    //         sm: { x: 0, y: 13, w: 6, h: 2 },
                    //         xs: { x: 0, y: 19, w: 4, h: 2 },
                    //         xxs: { x: 0, y: 17, w: 2, h: 2 }
                    //     },
                    //     component: "CHARTJS_DOUBLEAXIS",
                    //     config: {}
                    // }
                ],
                config: {
                    // color_schema:["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00", "#b82e2e", "#316395", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac"] // GOOGLE
                    // colorSchema: ["#005F73", "#0A9396", "#94D2BD", "#E9D8A6", "#EE9B00", "#CA6702", "#BB3E03", "#AE2012", "#9B2226"],
                    menuLayout: {
                        lg: [
                            { i: "datePicker", x: 0, y: 0, h: 0.25, w: 1, static: true },
                            { i: "editLayout", x: 3, y: 0, h: 0.25, w: 1, static: true }
                        ],
                        md: [
                            { i: "datePicker", x: 0, y: 0, h: 0.25, w: 1, static: true },
                            { i: "editLayout", x: 3, y: 0, h: 0.25, w: 1, static: true }
                        ],
                        sm: [{ i: "datePicker", x: 0, y: 0, h: 0.25, static: true }],
                        xs: [{ i: "datePicker", x: 0, y: 0, h: 0.25, static: true }],
                        xxs: [{ i: "datePicker", x: 0, y: 0, h: 0.25, static: true }]
                    },
                    breakPoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
                    cols: { lg: 12, md: 9, sm: 6, xs: 4, xxs: 2 }
                }
            }
            let data = [
                {
                    id: 0,
                    value: 0.6,
                    type: Number
                },
                {
                    id: 0,
                    value: 75,
                    type: Number
                },
                {
                    id: 0,
                    value: 75,
                    type: Number
                },
                {
                    id: 0,
                    value: 1.25154545,
                    type: Number
                },
                {
                    id: 0,
                    value: 1200,
                    type: Number
                },
                {
                    id: 0,
                    value: 1200,
                    type: Number
                },
                {
                    id: 0,
                    value: 45,
                    type: Number
                },
                {
                    id: 0,
                    value: 1200,
                    type: Number
                },
                {
                    id: 0,
                    value: 1200,
                    type: Number
                },
                {
                    id: 0,
                    value: wordCloudData,
                    type: Object
                },
                {
                    id: 0,
                    value: barData,
                    type: Object
                },
                {
                    id: 0,
                    value: pieData,
                    type: Object
                },
                {
                    id: 0,
                    value: pieData,
                    type: Object
                },
                {
                    id: 0,
                    value: pieData,
                    type: Object
                },
                {
                    id: 0,
                    value: pieData,
                    type: Object
                },
                {
                    id: 0,
                    value: lineData,
                    type: Object
                },
                {
                    id: 0,
                    value: lineData,
                    type: Object
                }
            ]
            setState({ metaData, data })
        }
    }, [])

    const [nsRecord] = useRecord({ entity: "NAMESPACE", lk: dashboardNamespaceReadlID })
    const [Record] = useRecord({ entity: "DASHBOARD", lk: dashboardRealID, namespace: dashboardNamespaceReadlID })
    const controller = new AbortController();


    useEffect(() => {

        if (!Record.checking && Record.data && nsRecord.data) {

            let curls: string[] = []
            Record.data.metrics.forEach(e => {
                curls.push(`${nsRecord.data.url}/data/metricvalue/${e.metricId}`)
            });

            const accessToken = AuthService.getAccessToken()
            let requests: any = []
            curls.forEach(e => {
                let httpConfig: any
                if (interval?.type == 'fixed') { httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken }, params: { from: dateRange.from, to: dateRange.to, fixedInterval: interval?.value } } }
                else if (interval?.type == 'calendar') { httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken }, params: { from: dateRange.from, to: dateRange.to, calendarInterval: interval?.value } } }
                const req = Axios.get(e, { ...httpConfig, signal: controller.signal })
                requests.push(req)
            })


            let metaData: any = {}

            metaData = {
                ...Record.data,
                config: JSON.parse(Record.data.config),
                metrics: Record.data.metrics.map(e => {
                    if (e.component.config != undefined) {
                        return {
                            ...e, layout: JSON.parse(e.layout), config: JSON.parse(e.component.config), interval: interval
                        }
                    }
                    else {
                        return {
                            ...e, layout: JSON.parse(e.layout), interval: interval
                        }
                    }

                })
            }

            let initData = Array(requests.length).fill(undefined)
            setState({ metaData: metaData, data: initData })

            // controller.abort()
            requests.forEach((req, index) => {
                req.then((response) => {
                    setState((prev) => {
                        const newResp = [...prev.data]
                        newResp[index] = {
                            id: index,
                            data: response.data.data,
                            type: response.data.data.type,
                        }
                        return { ...prev, data: newResp }

                    })
                }
                ).catch((err) => {
                    setState((prev) => {
                        const newResp = [...prev.data]
                        newResp[index] = {
                            id: index,
                            data: err.response.status,
                            type: 'error',
                        }
                        return { ...prev, data: newResp }
                    })
                });
            })
        }
    }, [Record, dateRange])

    useEffect(() => {

        console.log("ARRAY",state?.data.every(isUndefined))

        if (state?.data.every(isUndefined) === true) {
            setDatePickerDisabled(false)
        } else {
            setDatePickerDisabled(true)
        }
    }, [state])


    const handleDateChange = (d) => {
        let from: any
        let to: any
        d[0] == null ? from = moment().startOf('day').toDate() : from = d[0]
        d[1] == null ? to = moment().toDate() : to = d[1]
        from = moment.utc(from)
        to = moment.utc(to)
        setInterval(getFixedInterval(to, from))
        setDateRange({ from: from.toDate().toISOString(), to: to.toDate().toISOString() })
    }

    if (state == null)
        return <Components.Skeleton active={true} style={{ padding: 20 }} />

    
    return <Dashboard
        data={state.data}
        metaData={state.metaData}
        datePickerDisabled={datePickerDisabled}
        onDateChange={handleDateChange} />
}
