import { Badge, Card, Space, Tag, Typography } from 'antd';
import React, { memo, useState } from 'react';
import {
    Handle, NodeProps, Position
} from 'react-flow-renderer';
import { SafetyCertificateOutlined, HomeOutlined } from '@ant-design/icons';

const { Paragraph, Text } = Typography;



const ExpDescription = ({ message }: { message: string }) => {

    const [expanded, setExpanded] = useState(false)
    const [counter, setCounter] = useState(9)


    return <>
        <Paragraph key={counter}
            ellipsis={expanded ? false : {
                rows: 2,
                expandable: true,
                onExpand: () => {
                    setExpanded(true)
                },
            }}
        >
            {message}
            {expanded && <a onClick={() => { setExpanded(false); setCounter((prev) => prev + 1) }}> ...less</a>}
        </Paragraph>
    </>
}

const cardDefaultStyle = {
    width: 350
}

export default memo((props: NodeProps) => {
    const { data } = props
    const { intent, patternMatch, welcome, defaultAuthGuard } = data


    if (intent) {
        const { id, description } = intent
        return <div>
            <Badge.Ribbon text="FLOW" color="purple">
                <Card hoverable style={{ width: 400 }}>
                    <div style={{ position: "relative", padding: "10px", marginTop: "12px", paddingTop: "15px", border: "1px solid #e2e2e2", borderRadius: "5px" }}>
                        <div style={{ position: "absolute", top: "-12px" }}>{<Tag style={{ margin: 0 }} color="gold">INTENT RECOGNITION</Tag>}</div>
                        <Text strong>{id}</Text> - {description}
                    </div>
                    {
                        data.patternMatches &&
                        <div style={{ position: "relative", padding: "10px", marginTop: "20px", paddingTop: "15px", border: "1px solid #e2e2e2", borderRadius: "5px" }}>
                            <div style={{ position: "absolute", top: "-12px" }}>{<Tag style={{ margin: 0 }} color="gold">PATTERN MATCHES</Tag>}</div>
                            <Space>{data.patternMatches.map(pm => (<Text key={pm.id} strong>{pm.id}</Text>))}</Space>
                        </div>
                    }
                </Card>
                <Handle
                    type="source"
                    position={Position.Bottom}
                />
            </Badge.Ribbon>
        </div>
    }

    if (patternMatch) {
        const { id, description } = patternMatch
        return <div>

            <Badge.Ribbon text="FLOW" color="purple">
                <Card hoverable style={{ width: 400 }}>
                    <div style={{ position: "relative", padding: "10px", marginTop: "12px", paddingTop: "15px", border: "1px solid #e2e2e2", borderRadius: "5px" }}>
                        <div style={{ position: "absolute", top: "-12px" }}>{<Tag style={{ margin: 0 }} color="gold">PATTERN MATCH</Tag>}</div>
                        <Text strong>{id}</Text>
                    </div>
                </Card>
                <Handle
                    type="source"
                    position={Position.Bottom}
                />
            </Badge.Ribbon>
        </div>
    }

    if (welcome) {
        const { welcomeAction } = welcome
            return <div>
                <Card hoverable style={{ width: 400 }}>
                    <div style={{ textAlign: "center", color: "#096dd9" }}>
                        <HomeOutlined /> Welcome to Virtual Assistant
                    </div>
                </Card>
                <Handle
                    type="source"
                    position={Position.Bottom}
                />
            </div>
    }

    if (defaultAuthGuard) {
        const { action } = defaultAuthGuard
        return <div>
            <Card hoverable style={{ width: 400 }}>
                <div style={{ textAlign: "center", color: "red" }}>
                    <SafetyCertificateOutlined /> Default Authentication Check
                </div>
            </Card>
            <Handle
                type="source"
                position={Position.Bottom}
            />
        </div>
    }

    return null;
});