import FullFlowView from './FullFlowView';
import MoveEnv from './MoveEnv'
import {DashboardRoot} from './DashboardRoot'
import { FlowDashboard } from './flowDashboard/FlowDashboard';

const customComponents = {
    "moveEnv" : {
        displayName: "Move Environments",
        component: MoveEnv
    },
    "fullFlowView" : {
        displayName: "Full Flow View",
        component: FullFlowView
    },
    "dashboardRoot" : {
        displayName: "Dashboard Root",
        component: DashboardRoot
    },
    "flowDashboard": {
        displayName: "Flow Dashboard",
        component: FlowDashboard
    }
}

export default customComponents;