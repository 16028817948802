

export enum ComponentNames {
    GAUGE = "GAUGE",
    ANTD_RATE = "ANTD_RATE",
    ANTD_PROGRESS_CIRCLE = "ANTD_PROGRESS_CIRCLE",
    ANTD_STATISTIC = "ANTD_STATISTIC",
    VISX_WORDCLOUD = "VISX_WORDCLOUD",
    NIVO_BAR = "NIVO_BAR",
    NIVO_PIE = "NIVO_PIE",
    NIVO_LINE = "NIVO_LINE",
    CHARTJS_DOUBLEAXIS = "CHARTJS_DOUBLEAXIS"
}


export interface DashboardProps {
    onDateChange: any;
    data: any;
    metaData: {
        id: any,
        title: any,
        config: {
            colorSchema: any
            menuLayout: {}
            breakPoints: any
            cols: any
        },
        metrics: [any]
        interval: any
    };
    datePickerDisabled: boolean
}