import React from 'react';
import {
    Handle, NodeProps, Position
} from 'react-flow-renderer';

const GroupNode: React.FC<NodeProps> = (props: any) => {
	const { id, data } = props;
	const { children } = data ?? {};

	return null;

	return (
		<div
			id={`groupNode-${id}`}
			className="nodrag"
			style={{
				height: 10,
				width: props.data?.width ?? 100,
				border: '1px solid #979797',
				borderBottom: 'none',
			}}
		>
			<Handle
				type="source"
				position={Position.Top}
				style={{ background: 'transparent', border: 0, top: 0 }}
			/>
		</div>
	);
	
};

export default GroupNode;
