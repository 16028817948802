import { Badge, Card, Space, Tag, Typography } from 'antd';
import React, { memo, useState } from 'react';
import { SafetyCertificateOutlined, CloseSquareOutlined } from '@ant-design/icons';

import {
    Handle, NodeProps, Position
} from 'react-flow-renderer';

const { Paragraph } = Typography;

const AuthGuardNode: React.FC<NodeProps> = memo((props: any) => {
    const { id, data } = props;
    const { action } = data

    return <div>
        <Card style={{ width: 300 }}>
            <div style={{ textAlign: "center", color: "red" }}>
                <SafetyCertificateOutlined />                   Custom Authentication Check
            </div>
        </Card>
        <Handle
            type="target"
            position={Position.Top}
        />
        <Handle
            type="source"
            position={Position.Bottom}
        />
    </div>

})

export default AuthGuardNode;
