import { BranchesOutlined } from '@ant-design/icons';
import { Button, Card, Tag, Typography } from 'antd';
import React, { memo } from 'react';
import {
    Handle, NodeProps, Position, useStore, useZoomPanHelper
} from 'react-flow-renderer';

const { Paragraph } = Typography;



const cardDefaultStyle = {
    width: 350
}

export default memo((props: NodeProps) => {
    const { data } = props
    const { label, targetIntent } = data

    const store = useStore();
    const { setCenter } = useZoomPanHelper();

    const move = () => {
        const { nodes, height, transform } = store.getState();
        const [, , zoom] = transform;

        if (nodes.length) {
            const found = nodes.find((n) => n.type === "startNode" && n.id === targetIntent);

            if (found) {
                const x = found.__rf.position.x + found.__rf.width / 2;
                const y = found.__rf.position.y + height / 2 - 10;
                setCenter(x, y, zoom);
            }
        }
    }

    // extra={<Tag color={"purple"}>FLOW REF</Tag>} 

    return <div>
        <Card hoverable title={<div style={{ textAlign: "center", paddingBottom: "5px" }}>{label}</div>} style={cardDefaultStyle}>
            <div style={{ position: "relative" }}>
                <div style={{
                    position: "absolute",
                    top: "-34px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    textAlign: "center"
                }}>
                    <Tag color={"purple"}>FLOW REFERENCE</Tag>
                </div>
                <Button onClick={move} icon={<BranchesOutlined />} block>Move to flow</Button>
            </div>
        </Card>
        <Handle
            type="target"
            position={Position.Top}
        />
    </div>

});