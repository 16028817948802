import { AuthService } from "@gemini-projects/gemini-react-admin-app";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";

export const FlowDashboard = ((props) => {

    const { dashboardUrl } = props;
    const dashboardUrlValue = dashboardUrl.value

    const { pathname, search } = useLocation();
    const history = useHistory();


    const [loaded, setLoaded] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const accessToken = AuthService.getAccessToken()


    useEffect(() => {
        if (iframeRef.current && loaded) {
            const token = accessToken
            const searchparams = Object.fromEntries(new URLSearchParams(search));
            const initJSON = {
                AUTH_TOKEN: token,
                SEARCH_PARAMS: searchparams
            }
            // console.log(initJSON)
            iframeRef.current.contentWindow?.postMessage({ type: 'INIT', DATA: initJSON }, '*');
        }
    }, [iframeRef, loaded])


    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'SET_URL') {
                const params = new URLSearchParams(event.data.DATA.SEARCH_PARAMS);
                history.replace({ pathname: pathname, search: params.toString() });
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    /** to get and use the full div of the child
    const [iframeHeight, setIframeHeight] = useState('200px');
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'SET_HEIGHT') {
                setIframeHeight(`${event.data.height}px`);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
     */

    const handleIframeLoad = () => {
        // after half second because sometimes the event is sent before the react inner page actually load
        setTimeout(() => { setLoaded(true) }, 500)
    };


    return <div style={{ width: "100%", minHeight: "100%", height: "100%" }}>
        <iframe
            height={"100%"}
            ref={iframeRef}
            onLoad={handleIframeLoad}
            width={"100%"} src={dashboardUrlValue} style={{
                margin: 0,
                padding: 0,
                // overflow: "auto",
                border: "none"
            }} />
    </div>

})
