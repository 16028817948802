import { Card, Space, Tag } from 'antd';
import React, { memo } from 'react';
import {
    Handle, NodeProps, Position
} from 'react-flow-renderer';



const cardDefaultStyle = {
    width: 400,
    // boxShadow: "rgb(0 0 0 / 8%) 0px 0px 2px 1px"
}

export default memo((props: NodeProps) => {
    const { data } = props
    const { usecase } = data
    const { label, description } = usecase

    return <div className='flowCard'>
        <Card hoverable title={<div style={{ textAlign: "center", paddingBottom: "5px" }}>{label}</div>} style={cardDefaultStyle}>
            <div style={{ position: "relative" }}>
                <div style={{
                    position: "absolute",
                    top: "-34px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    textAlign: "center"
                }}>
                    <Space><Tag style={{ margin: 0 }} color={'geekblue'}>USE CASE</Tag></Space>
                </div>
                {description}
            </div>
        </Card>
        <Handle key={"target"}
            type="target"
            position={Position.Top}
        />
        <Handle key={"source"}
            type="source"
            position={Position.Bottom}
        />
    </div>
});