import { ClusteringDashboard as CD } from '@aitechnologies-it/va-backoffice-dashboard';
import { AuthService } from "@gemini-projects/gemini-react-admin-app";
import { Alert, Progress, Row } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

const DASHBOARD_DATA_URI = process.env.REACT_APP_CLUSTERING_DASHBOARD_DATA_API
const COMPARISON_DATA_URI = process.env.REACT_APP_COMPARISON_METADATA_API

const ClusteringDashboard = (props: any) => {
    const { id } = useParams<any>();

    const request_data_uri = DASHBOARD_DATA_URI + "/" + id;
    const request_comparison_data_uri = COMPARISON_DATA_URI + "?experiments.expAid=" + id;


    const [data, setData] = useState<any>(undefined)
    const [error, setError] = useState<boolean>(false)
    const [comparisonData, setComparisonData] = useState<any>(undefined);

    const [loadingState, setLoadingState] = useState<any>(0)


    const accessToken = AuthService.getAccessToken();

    useEffect(() => {
        async function data() {
            let httpConfig = {
                headers: { 'Authorization': 'Bearer ' + accessToken },
            }
            try {
                const resp = await Axios.get(request_data_uri, httpConfig);
                const gsHttpConfig = {
                    onDownloadProgress: (progressEvent: any) => {
                        if (progressEvent.total) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setLoadingState(percentCompleted)
                        }
                    }
                }
                const googleResp = await Axios.get(resp.data, gsHttpConfig);
                setTimeout(() => setData(googleResp.data), 100)


            } catch (e) {
                setError(true)
            }
        }

        async function comparisonData() {
            let httpConfig = {
                headers: { 'Authorization': 'Bearer ' + accessToken },
            }
            try {
                const resp = await Axios.get(request_comparison_data_uri, httpConfig);

                if (resp.data?.data) {
                    const comparisons = resp.data?.data;
                    const curl = comparisons.map(c => ({ ...c, url: "/clusteringcomparison/" + c.id }))
                    setComparisonData(curl)
                }


            } catch (e) {
                console.error(e)
            }
        }


        data();
        comparisonData();

    }, [])

    return data ? <div style={{ minHeight: '100vh' }}><CD data={data} comparisonList={comparisonData} /></div> : (
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
            {error ? <Alert
                message={`Error getting the experiment ${id}`}
                showIcon
                description=""
                type="error"
            /> :    //<Spin size="large" />
                <Progress type="circle" percent={loadingState} />
            }
        </Row>

    )

}

export default ClusteringDashboard
