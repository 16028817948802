import { ResponsiveBar } from '@nivo/bar'
import moment from 'moment';
import { Empty } from 'antd';

interface BarPlotProps {
    data: any;
    config: any;
    interval: any
}

const intervalMap: any = {
    '1s': { sec: 1, text: '1 Second' },
    '5s': { sec: 5, text: '5 Seconds' },
    '30s': { sec: 30, text: '30 Seconds' },
    '1m': { sec: 60, text: '1 Minute' },
    '5m': { sec: 300, text: '5 Minutes' },
    '30m': { sec: 1800, text: '30 Minutes' },
    '1h': { sec: 3600, text: '1 Hour' },
    '3h': { sec: 10800, text: '3 Hours' },
    '12h': { sec: 43200, text: '12 Hours' },
    '1d': { sec: 86400, text: '1 Day' },
    '1w': { sec: 604800, text: '1 Week' },
    '1M': { sec: 2.628e+6, text: '1 Month' },
}


const getAllXTicks = (data) => {
    let intervals: any = [];
    let intervalLength = Math.round(data.length / data.length)
    for (let i = 0; i <= data.length - 1; i++) {
        intervals.push(data[i * intervalLength].date)
    }
    return intervals
}


const getXTickValues = (data, interval) => {
    let intervals: any = [];
    if (intervalMap[interval.value].sec <= 604800) {
        if (data.length > 10) {
            let intervalLength = Math.round(data.length / 5)
            for (let i = 0; i <= 4; i++) {
                intervals.push(data[i * intervalLength].date)
            }
        }
        else if (data.length < 10 && data.length > 0) {
            intervals = getAllXTicks(data)
        }
        else {
            intervals.push('')
        }
    }
    else if (intervalMap[interval.value].sec >= 604800) {
        intervals = getAllXTicks(data)
    }
    return intervals
}

const getXTickFormat = (interval) => {
    if (intervalMap[interval.value].sec <= 3600) {
        return "HH:mm"
    }
    else if (intervalMap[interval.value].sec > 3600 && intervalMap[interval.value].sec <= 604800) {
        return "DD/MM/YY"
    }
    else if (intervalMap[interval.value].sec > 604800) {
        return "MMM YY"
    }
}


export const BarPlot = ((props: BarPlotProps) => {

    let { data, config, interval } = props
    let comp: any
    let field : string
    config.field != undefined ? field = config.field : field = 'count'

    const getColor = () => {
        if (config?.color == undefined) { return "#69c0ff" } else { return config.color }
    }

    const tooltip = (props) => {
        let date: any
        date = `${moment(props.indexValue).format("DD/MM/YY HH:mm")}`
        return <div className="barToolTip">
            <p><b>Date: </b>{date}<br></br>
                <b>Count: </b>{props.data[field]}</p>
        </div >
    }

    if (data.length == 0 || data == undefined) {
        return comp = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Data!"} />
    }
    else {
        const xTickValues = getXTickValues(data, interval)
        comp =
            <ResponsiveBar
                animate={false}
                data={data}
                keys={[
                    field
                ]}
                indexBy="date"
                indexScale={{ type: 'band', round: false }}
                margin={{ top: 20, right: 10, left: 55, bottom: 50 }}
                colors={getColor()}
                borderRadius={2}
                enableLabel={false}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                tooltip={tooltip}

                axisBottom={{
                    tickSize: 0,
                    tickPadding: 8,
                    tickRotation: 0,
                    tickValues: xTickValues,
                    legend: `Time Intervals: ${intervalMap[interval.value].text}`,
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: d => moment(d).format(getXTickFormat(interval))
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: e => Math.floor(e) === e && e
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
            />
    }
    return comp
})