import { Button, Badge, Card, Divider, Popover, Space, Tabs, Tag, Typography, Collapse } from 'antd';
import { SafetyCertificateOutlined, CloseSquareOutlined } from '@ant-design/icons';
import React, { memo, useState } from 'react';
import {
    Handle, NodeProps, Position, useStore, useZoomPanHelper
} from 'react-flow-renderer';

const { Panel } = Collapse;
const { Paragraph, Text } = Typography;
const { TabPane } = Tabs;



const tagFromActionType = (actionType: string) => {
    let color = "blue"
    switch (actionType) {
        case "ACTION_LIST":
            color = "orange";
            break;
        case "SIMPLE_REDIRECT":
            color = "green";
            break;
        case "MESSAGE":
        case "MESSAGE_LIST":
            color = "purple";
            break;
    }
    return <Tag style={{ margin: 0 }} color={color}>{actionType.split("_").join(" ")}</Tag>
}

const ExpDescription = ({ message }: { message: string }) => {

    const [expanded, setExpanded] = useState(false)
    const [counter, setCounter] = useState(9)

    return <>
        <Paragraph key={counter}
            style={{whiteSpace: "pre-wrap"}}
            ellipsis={expanded ? false : {
                rows: 2,
                expandable: true,
                onExpand: () => {
                    setExpanded(true)
                },
            }}
        >
            {message}
            {expanded && <><br/><a onClick={() => { setExpanded(false); setCounter((prev) => prev + 1) }}>Reduce</a></>}
        </Paragraph>
    </>
}

const cardDefaultStyle = {
    width: 400,
    // boxShadow: "rgb(0 0 0 / 8%) 0px 0px 2px 1px"
}

export default memo((props: NodeProps) => {
    const { data } = props
    const { action } = data
    const { actionType } = action

    const store = useStore();
    const { setCenter } = useZoomPanHelper();

    const move = () => {
        const { nodes, height, transform } = store.getState();
        const [, , zoom] = transform;

        if (nodes.length) {
            const found = nodes.find((n) => n.id === "S_DEFAULT_AUTH_GUARD");

            if (found) {
                const x = found.__rf.position.x + found.__rf.width / 2;
                const y = found.__rf.position.y + height / 2 - 10;
                setCenter(x, y, zoom);
            }
        }
    }

    let defaultAuthButton: any = null;
    if (action.authGuard?.enabled && !action.authGuard?.action) {
        defaultAuthButton = <Button danger onClick={move} icon={<SafetyCertificateOutlined />} size="small"></Button>
    }

    const handles = [<Handle key={"target"}
        type="target"
        position={Position.Top}
    />]

    let body: any = null;

    if (actionType == "ACTION_LIST") {
        const { message, actions } = action.actionList

        body = <><ExpDescription  message={message} />
            <Space size="small" wrap>
                {actions && actions.map((a: any) => <Tag key={a.label} color="blue">{a.label}</Tag>)}
            </Space></>

        handles.push(<Handle
            key={"source"}
            type="source"
            position={Position.Bottom}
        />)
    }

    if (actionType == "SIMPLE_REDIRECT") {
        const { prefixMessage, url } = action.simpleRedirect

        body = <>
            <ExpDescription message={prefixMessage} />
            <div><a href={url}>{url}</a></div>
        </>
    }

    if (actionType == "MESSAGE") {
        const { value } = action.message
        const message = value ? value : action.message;
        body = <ExpDescription message={message} />;
    }

    if (actionType == "MESSAGE_LIST") {
        const { messages } = action.messageList
        body = messages.map((message: any, i) => <ExpDescription key={i} message={message} />)
    }

    if (actionType == "QUESTION_FLOW") {
        const { flow } = action.questionFlow

        body = <Collapse ghost>
            {flow.map((flow: any, i) =>
                <Panel header={<><Tag>{flow.answerType == "ATTACHMENT" ? "Request Attachment" : "Question " + (i + 1)}</Tag>
                    <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {flow.question}
                    </span></>
                } key={i}>
                    {flow.question}
                </Panel>)
            }
        </Collapse>
        handles.push(<Handle key={"source"}
            type="source"
            position={Position.Bottom}
        />)
    }

    if (actionType == "CLOSE_SESSION") {
        const { message, falseLabel, trueLabel } = action.closeSession

        body = <><ExpDescription message={message} />
            <Space size="small" wrap>
                <Tag key="true" color="blue">{trueLabel}</Tag>
                <Tag key="false" color="blue">{falseLabel}</Tag>
            </Space>
        </>
    }

    if (actionType == "ASK_CONTACT_CENTER") {
        const { message, falseLabel, trueLabel } = action.askContactCenter
        body = <><ExpDescription message={message} />
            <Space size="small" wrap>
                <Tag key="true" color="blue">{trueLabel}</Tag>
                <Tag key="false" color="blue">{falseLabel}</Tag>
            </Space>
        </>
    }

    if (actionType == "SWITCH_CONTACT_CENTER") {
        const { message } = action.switchContactCenter
        body = <ExpDescription message={message} />
    }

    if (actionType === "JS_CODE") {
        const { actionJs, jsExecutedRes, useCases } = data

        const realUseCases = useCases.filter(d => Array.isArray(d.actions) && d.actions.includes(action.id))

        if (!jsExecutedRes && Array.isArray(realUseCases) && realUseCases.length > 0)
            body = <div style={{ textAlign: "center" }}>
                Use cases: {realUseCases.reduce( (summ, uc) => summ + uc.useCases.length, 0 )}
            </div>



        if (jsExecutedRes) {
            // TODO 
            body = <div style={{ textAlign: "center" }}>
               TODOOO
            </div>
        }

        handles.push(<Handle
            key={"source"}
            type="source"
            position={Position.Bottom}
        />)
    }

    // const title = action.isJsExecuted ? "" : <div style={{ textAlign: "center", paddingBottom: "5px" }}>{action.id}</div>
    const title = <div style={{ textAlign: "center", paddingBottom: "5px" }}>{action.label ?? action.id}</div>


    return <div className='flowCard'>
        <Card hoverable title={title} style={cardDefaultStyle}>
            <div style={{ position: "relative" }}>
                <div style={{
                    position: "absolute",
                    top: "-34px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    zIndex: 5
                }}>
                    <Space>{tagFromActionType(actionType)}{defaultAuthButton}</Space>
                </div>
                {body}
            </div>
        </Card>
        {handles}
    </div>
});