
import moment from 'moment';
import { DateRangeInput, DateFormatProps } from "@blueprintjs/datetime";

export const DatePickerComp = (props) => {

    let { onDateChange, onTagChange, disabled } = props

    const getMomentFormatter = (format: string): DateFormatProps => {
        return {
            formatDate: (date) => moment(date).format(format),
            parseDate: (str) => moment(str, format).toDate(),
        }
    };


    let today: [any, any] = [moment().startOf('day').toDate(), moment().toDate()]
    let yesterday: [any, any] = [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]
    let this_week: [any, any] = [moment().startOf('week').toDate(), moment().toDate()]
    let this_month: [any, any] = [moment().startOf('month').toDate(), moment().toDate()]
    let last_month: [any, any] = [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
    let last_15_minutes: [any, any] = [moment().subtract(15, 'minutes').toDate(), moment().toDate()]
    let last_1_hour: [any, any] = [moment().subtract(1, 'hours').toDate(), moment().toDate()]
    let last_24_hours: [any, any] = [moment().subtract(24, 'hours').toDate(), moment().toDate()]
    let last_7_days: [any, any] = [moment().subtract(7, 'days').toDate(), moment().toDate()]
    let last_30_days: [any, any] = [moment().subtract(30, 'days').toDate(), moment().toDate()]
    let last_365_days: [any, any] = [moment().subtract(365, 'days').toDate(), moment().toDate()]
    let all_time: [any, any] = [moment('2021-05-01').toDate(), moment().toDate()]

    const handleChange = (range) => {
        onDateChange(range)
        if (range[0] === today[0] && range[1] === today[1]) { onTagChange("Today") }
        else if (range[0] === yesterday[0] && range[1] === yesterday[1]) { onTagChange("Yesterday") }
        else if (range[0] === this_week[0] && range[1] === this_week[1]) { onTagChange("This Week") }
        else if (range[0] === this_month[0] && range[1] === this_month[1]) { onTagChange("This Month") }
        else if (range[0] === last_month[0] && range[1] === last_month[1]) { onTagChange("Last Month") }
        else if (range[0] === last_15_minutes[0] && range[1] === last_15_minutes[1]) { onTagChange("Last 15 Minutes") }
        else if (range[0] === last_1_hour[0] && range[1] === last_1_hour[1]) { onTagChange("Last 1 Hour") }
        else if (range[0] === last_24_hours[0] && range[1] === last_24_hours[1]) { onTagChange("Last 24 Hours") }
        else if (range[0] === last_7_days[0] && range[1] === last_7_days[1]) { onTagChange("Last 7 Days") }
        else if (range[0] === last_30_days[0] && range[1] === last_30_days[1]) { onTagChange("Last 30 Days") }
        else if (range[0] === last_365_days[0] && range[1] === last_365_days[1]) { onTagChange("Last 365 Days") }
        else if (range[0] === all_time[0] && range[1] === all_time[1]) { onTagChange("All Time") }
        else { onTagChange(undefined) }
    }

    const DatePicker = <DateRangeInput
        {...getMomentFormatter('LL HH:mm:ss')}
        // locale={'de'}
        disabled={false}
        className='DateRangeInput'
        highlightCurrentDay={true}
        onChange={handleChange}
        allowSingleDayRange={true}
        closeOnSelection={true}
        defaultValue={[moment().startOf('day').toDate(), moment().toDate()]}
        timePrecision={'minute'}
        timePickerProps={{
            showArrowButtons: true,
        }}
        maxDate={moment().toDate()}
        minDate={moment('2021-05-01').toDate()}
        shortcuts={
            [
                { label: 'Today', dateRange: today, includeTime: true },
                { label: 'Yesterday', dateRange: yesterday, includeTime: true },
                { label: 'This Week', dateRange: this_week, includeTime: true },
                { label: 'This Month', dateRange: this_month, includeTime: true },
                { label: 'Last Month', dateRange: last_month, includeTime: true },
                { label: 'Last 15 Minutes', dateRange: last_15_minutes, includeTime: true },
                { label: 'Last 1 hour', dateRange: last_1_hour, includeTime: true },
                { label: 'Last 24 hours', dateRange: last_24_hours, includeTime: true },
                { label: 'Last 7 days', dateRange: last_7_days, includeTime: true },
                { label: 'Last 30 days', dateRange: last_30_days, includeTime: true },
                { label: 'Last 365 Days', dateRange: last_365_days, includeTime: true },
                { label: 'All Time', dateRange: all_time, includeTime: true },
            ]
        }
    />
    return DatePicker
}