
export const callbacks = (props: { namespace: string, entity: string }) => {
    const { namespace, entity } = props;
    if (entity === "CLUSTERINGANALYSIS")
        return {
            onRecordView: (lk: any) => {
                window.open(`/clusteringdashboard/${lk}`, "_blank")
            }
        }

    if (entity === "CLUSTERINGCOMPARISON")
        return {
            onRecordView: (lk: any) => {
                window.open(`/clusteringcomparison/${lk}`, "_blank")
            }
        }
    return undefined
}

export default callbacks;