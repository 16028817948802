import { PartitionOutlined } from '@ant-design/icons';
import { Card, Typography } from 'antd';
import React, { memo } from 'react';

import {
    Handle, NodeProps, Position
} from 'react-flow-renderer';

const { Paragraph } = Typography;

const ChannelOverrideNode: React.FC<NodeProps> = memo((props: any) => {
    const { id, data } = props;
    const { action } = data

    return <div>
        <Card style={{ width: 300 }}>
            <div style={{ textAlign: "center", color: "green" }}>
            <PartitionOutlined />   Channels Path
            </div>
        </Card>
        <Handle
            type="target"
            position={Position.Top}
        />
        <Handle
            type="source"
            position={Position.Bottom}
        />
    </div>

})

export default ChannelOverrideNode;
