import ClusteringComparison from './ClusteringComparison'
import ClusteringDashboard from './ClusteringDashboard'


export default [
    {
        route: "/clusteringdashboard/:id",
        component: ClusteringDashboard,
        exact: false,
        fullScreen: true
    },
    {
        route: "/clusteringcomparison/:id",
        component: ClusteringComparison,
        exact: false,
        fullScreen: true
    },
]