
import { Chart } from 'react-chartjs-2';
import moment from 'moment';
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { useState } from 'react'


import {
    Chart as ChartJS,
    registerables
} from 'chart.js';


interface DoubleAxisPlotProps {
    data: any
    config: any
    interval: any
}

const intervalMap: any = {
    '1s': { sec: 1, text: '1 Second' },
    '5s': { sec: 5, text: '5 Seconds' },
    '30s': { sec: 30, text: '30 Seconds' },
    '1m': { sec: 60, text: '1 Minute' },
    '5m': { sec: 300, text: '5 Minutes' },
    '30m': { sec: 1800, text: '30 Minutes' },
    '1h': { sec: 3600, text: '1 Hour' },
    '3h': { sec: 10800, text: '3 Hours' },
    '12h': { sec: 43200, text: '12 Hours' },
    '1d': { sec: 86400, text: '1 Day' },
    '1w': { sec: 604800, text: '1 Week' },
    '1M': { sec: 2.628e+6, text: '1 Month' },
}

const getXTickFormat = (interval) => {
    if (intervalMap[interval.value].sec <= 3600) {
        return "HH:mm"
    }
    else if (intervalMap[interval.value].sec > 3600 && intervalMap[interval.value].sec <= 604800) {
        return "DD/MM HH:mm"
    }
    else if (intervalMap[interval.value].sec > 604800) {
        return "MMM YY"
    }
}

const getDataDoubleAxisFormat = (data, config, interval) => {

    let chartjsdata: any = {
        labels: [],
        datasets: [
            { ...config.datasets.line, data: [] },
            { ...config.datasets.bar, data: [] }
        ]
    }

    let dateFormat = getXTickFormat(interval)

    let lineField = config.datasets.line.field == undefined ? 'count' : config.datasets.line.field
    let barField = config.datasets.bar.field == undefined ? 'value' : config.datasets.bar.field

    data.forEach(e => {
        chartjsdata.labels.push(moment(e.date).format(dateFormat))
        chartjsdata.datasets[0].data.push(e[`${lineField}`] == undefined ? 0 : e[`${lineField}`])
        chartjsdata.datasets[1].data.push(e[`${barField}`])
    });

    const chratjsoptions = {
        responsive: true,
        plugins: {
            legend: config.legend,
            tooltip: {
                callbacks: {
                }
            }
        },
        interaction: {
            mode: 'index' as const,
            intersect: true,
        },
        stacked: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: `Time Intervals: ${intervalMap[interval.value].text}`
                },
                time: {
                    displayFormat: dateFormat
                }
            },
            y0: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                title: config.scales.y0.title
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
                title: config.scales.y1.title

            },
        },
    };

    return { chartjsdata, chratjsoptions }
}

export const DoubleAxisPlot = ((props: DoubleAxisPlotProps) => {

    let { data, interval, config } = props
    const [convertedData, setConvertedData] = useState(getDataDoubleAxisFormat(data, config, interval))

    ChartJS.register(...registerables)

    const DoubleAxisPlot =
        <ParentSize style={{ margin: 20 }}>
            {({ width, height }) => (
                <Chart type='bar' options={convertedData.chratjsoptions} data={convertedData.chartjsdata} height={height} width={width} />)}
        </ParentSize>

    return DoubleAxisPlot
}
)

