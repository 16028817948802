
import { useState, useEffect } from 'react'

import { Statistic, Progress, Spin, Button, Rate, Tag } from 'antd'
import { WarningOutlined } from '@ant-design/icons';

import GaugeChart from 'react-gauge-chart';

import { DatePickerComp } from 'components/metricComponents/DatePicker'
import { BarPlot } from 'components/metricComponents/BarPlot'
import { LinePlot } from 'components/metricComponents/LinePlot'
import { PiePlot } from 'components/metricComponents/PiePlot'
import { DoubleAxisPlot } from 'components/metricComponents/DoubleAxisPlot'
import { WordCloudPlot } from 'components/metricComponents/WordCloudPlot'
import { ComponentNames, DashboardProps } from 'components/metricComponents/types'

import { WidthProvider, Responsive } from "react-grid-layout";

import loadable from "@loadable/component";


const ResponsiveReactGridLayout = WidthProvider(Responsive);


const getDefaultLayout = (metrics, editMode) => {
    let layouts: any = { lg: [], md: [], sm: [], xs: [], xxs: [] }
    metrics.map((item, index) => {
        layouts.lg.push({ i: index.toString(), ...item.layout.lg, static: editMode })
        layouts.md.push({ i: index.toString(), ...item.layout.md, static: editMode })
        layouts.sm.push({ i: index.toString(), ...item.layout.sm, static: editMode })
        layouts.xs.push({ i: index.toString(), ...item.layout.xs, static: editMode })
        layouts.xxs.push({ i: index.toString(), ...item.layout.xs, static: editMode })
    })
    return layouts
}

const changeMode = (layout, mode) => {
    let lgMode = layout.lg.map((obj, i) => ({ ...obj, static: mode }))
    return { 'lgMode': lgMode }
}


export const Dashboard = ((props: DashboardProps) => {

    let { metaData, data, onDateChange,  datePickerDisabled} = props
    const [editMode, setEditMode] = useState(true)
    const [editButtonText, setEditButtonText] = useState("Edit Layout")
    const [dateTag, setDateTag] = useState("Today")
    const [editModeActive, setEditModeActive] = useState(false)

    const defaultLayout = getDefaultLayout(metaData.metrics, editMode)
    let LocalStorageLayout = localStorage.getItem('layouts')

    const [layouts, setLayout] = useState(LocalStorageLayout != null ? JSON.parse(LocalStorageLayout) : defaultLayout)

    const colorSchema = metaData.config.colorSchema
    const breakPoints = metaData.config.breakPoints
    const cols = metaData.config.cols
    let colorPallet: any
    let error = <WarningOutlined className="center" style={{ fontSize: 32, color: "#cf1322" }} />

    useEffect(() => {
        let updatedLayout = changeMode(layouts, editMode)
        let lgMode = updatedLayout.lgMode
        setLayout({ ...layouts, lg: lgMode })
    }, [editMode])

    const handleEditLayout = () => {
        setEditMode(!editMode)
        editMode ? setEditButtonText("Save Layout") : setEditButtonText("Edit Layout")
    }

    const handleLayoutChange = (layout) => {
        setLayout({ ...layouts, lg: layout })
        localStorage.setItem('layouts', JSON.stringify(layouts))
        // let LocalStorageLayout = localStorage.getItem('layouts')
        // LocalStorageLayout != null ? console.log(JSON.parse(LocalStorageLayout)) : console.log("Nothing")
    }

    const handleEditModeActivation = () => {
        if (window.innerWidth > 1400) {
            setEditModeActive(false)
        } else {
            setEditModeActive(true)
        }
    }

    const handleDateTagChange = (tag) => {
        setDateTag(tag)
    }

    const getDynamicIcon = (name) => {
        return loadable(() =>
            import(`@ant-design/icons/es/icons/${name}.js`)
                .catch(err => import(`@ant-design/icons/es/icons/WarningOutlined.js`)))
    }

    const getErrorORComp = (item, index, component) => {
        if (data[index].type == 'error') {
            return error
        } else {
            switch (component) {
                case ComponentNames.ANTD_STATISTIC:

                    if (item.config?.icon) {
                        if (item.config?.icon.position == 'center') {
                            const styleObject = {
                                "fontSize": item.config.icon.size,
                                "margin": 'auto',
                                "width": "100%",
                                "color": item.config.icon.color
                            }

                            let DynamicIcon = getDynamicIcon(item.config.icon.name)

                            return <div className="center" >
                                <DynamicIcon type={item.config.icon.name} style={styleObject} />
                                <Statistic
                                    {...item.config?.antdCompConfig}
                                    value={data[index].data.value}
                                />
                            </div>
                        }
                        else {
                            if (item.config?.icon.position == 'left') {
                                let DynamicIcon = getDynamicIcon(item.config.icon.name)

                                return <div className="center" >
                                    <Statistic
                                        prefix={<DynamicIcon type={item.config.icon.name} style={{ fontSize: item.config.icon.size, color: item.config.icon.color }} />}
                                        {...item.config?.antdCompConfig}
                                        value={data[index].data.value}
                                    />
                                </div>
                            }

                            else if (item.config?.icon.position == 'right') {
                                let DynamicIcon = getDynamicIcon(item.config.icon.name)
                                return <div className="center" >
                                    <Statistic
                                        suffix={<DynamicIcon type={item.config.icon.name} style={{ fontSize: item.config.icon.size, color: item.config.icon.color }} />}
                                        {...item.config?.antdCompConfig}
                                        value={data[index].data.value}
                                    />
                                </div>
                            }

                            else {
                                return <div className="center" >
                                    <Statistic
                                        {...item.config?.antdCompConfig}
                                        value={data[index].data.value}
                                    />
                                </div>
                            }
                        }
                    }
                    else {
                        return <Statistic key={index}
                            {...item.config}
                            value={data[index].data.value}
                            className="center" />
                    }

                case ComponentNames.GAUGE:
                    return <GaugeChart id="gauge-chart2" key={index}
                        nrOfLevels={10}
                        arcPadding={0.1}
                        cornerRadius={3}
                        percent={data[index].data.value}
                        className="center"
                        textColor={"Black"}
                        animate={false}
                    />

                case ComponentNames.ANTD_RATE:
                    return <Rate key={index}
                        disabled
                        defaultValue={data[index].data.value}
                        className="center"
                    />

                case ComponentNames.ANTD_PROGRESS_CIRCLE:
                    return <Progress key={index}
                        {...item.config}
                        type="circle"
                        percent={data[index].data.value}
                        className="center"
                        strokeColor={colorPallet}
                    />
                case ComponentNames.NIVO_BAR:
                    return <BarPlot key={index} data={data[index].data} config={item.config} interval={metaData.metrics[index].interval} />

                case ComponentNames.VISX_WORDCLOUD:
                    return <WordCloudPlot key={index} data={data[index].data} colorPallet={metaData.config.colorSchema} />

                case ComponentNames.NIVO_PIE:
                    return <PiePlot key={index} data={data[index].data} colorPallet={metaData.config.colorSchema} />

                case ComponentNames.NIVO_LINE:
                    return <LinePlot key={index} data={data[index].data} config={item.config} interval={metaData.metrics[index].interval} />

                case ComponentNames.CHARTJS_DOUBLEAXIS:
                    return <DoubleAxisPlot key={index} data={data[index].data} config={item.config} interval={metaData.metrics[index].interval} />
            }
        }
    }


    let components = metaData.metrics.map((item, index) => {
        const component: ComponentNames = item.component.name
        switch (component) {
            case ComponentNames.ANTD_STATISTIC:
            case ComponentNames.GAUGE:
            case ComponentNames.ANTD_RATE:
            case ComponentNames.ANTD_PROGRESS_CIRCLE:
                return <div
                    key={index}
                    className="containerStatWithTitle"
                >
                    {
                        data[index] != undefined
                            ? getErrorORComp(item, index, component)
                            : <Spin spinning={true} className="center"></Spin>
                    }
                    <p className="statTitle">{item.title}</p>
                </div >

            case ComponentNames.NIVO_BAR:
            case ComponentNames.VISX_WORDCLOUD:
            case ComponentNames.NIVO_PIE:
            case ComponentNames.NIVO_LINE:
            case ComponentNames.CHARTJS_DOUBLEAXIS:
                {
                    return <div
                        key={index}
                        className="containerWithTitle"
                    >
                        <p className="plotTitle">{item.title}</p>
                        {

                            data[index] != undefined
                                ? getErrorORComp(item, index, component)
                                : <Spin spinning={true} className="center"></Spin>
                        }
                    </div >
                }
        }
    })

    return (
        <>
            <ResponsiveReactGridLayout
                className="menu"
                breakpoints={breakPoints}
                cols={cols}
                layouts={metaData.config.menuLayout}
            >
                <div key="a" data-grid={{ x: 0, y: 0, h: 0.25, w: 2.5, static: true }} className='datePicker' >
                    <DatePickerComp onDateChange={onDateChange} onTagChange={handleDateTagChange} disabled={datePickerDisabled}/>
                </div>
                <div key="editLayout" className='editLayout' > <Button onClick={handleEditLayout} disabled={editModeActive}>{editButtonText} </Button> </div>
                {dateTag != undefined && <div key="b" data-grid={{ x: 2.7, y: 0, w: 1, h: 0.25, static: true }}> <Tag color="blue" style={{ fontSize: 14, padding: 4 }}>{dateTag}</Tag> </div>}
            </ResponsiveReactGridLayout >

            <ResponsiveReactGridLayout
                className="layout"
                layouts={layouts}
                breakpoints={breakPoints}
                cols={cols}
                onDrag={handleLayoutChange} // OnDragStop doesn't work correctly in this case. onDrag is used, which is a bit slower. 
                onResize={handleLayoutChange} // OnResizeStop doesn't work correctly in this case. OnResize is used, which is a bit slower. 
                onLayoutChange={handleEditModeActivation}

            >
                {components}
            </ResponsiveReactGridLayout >

        </>
    )
}
)