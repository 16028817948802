import { ResponsivePieCanvas } from '@nivo/pie'
import { Empty } from 'antd';

interface PiePlotProps {
    data: any;
    colorPallet: any
}

export const PiePlot = ((props: PiePlotProps) => {

    let { data, colorPallet } = props
    let comp: any

    if (data.length == 0 || data == undefined) {
        return comp = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Data!"} />
    }
    else {
        let pieData: any = []

        data.forEach(function (obj, i) {
            pieData.push(
                {
                    id: obj.key,
                    label: obj.key,
                    value: obj.count,
                    color: colorPallet[i % colorPallet.length],
                }
            )
        });

        comp =
            <ResponsivePieCanvas
                data={pieData}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                colors={colorPallet}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
            />
    }

    return comp
})